( function( global, $ ) {
    var $maximum = 10;

    if (global.methods.isMobile('breakpoint', 'lg') === true) {
        $maximum = 6;
    }

    if (global.methods.isMobile('breakpoint', 'sm') === true) {
        $maximum = 4;
    }

    $('[gr-show-all]').each(function(){
        $children = $(this).find('[gr-show-all-target]').children().length;

        if ($children <= $maximum) {
            $(this).find('[gr-show-all-trigger]').hide();
        }
    });

   $('[gr-show-all-trigger]').on('click', function() {
       $(this).parent().find('[gr-show-all-target]').children().css('display', 'block');
       $(this).hide();
   });

}( window.groots, jQuery ));
