( function( global ) { // eslint-disable-line

    var pluginName = 'grLazyLoader';

    var hasChange = false;
    var hasWebPSupport = false;
    var windowWidth = null;
    var windowHeight = null;




    var updateWindowSize = function () {
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
    };

    var setChanged = function () {
        hasChange = true;
    };

    // Constructor
    function grLazyLoader(selector) {

        // Create global references
        var _this = this;
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        // Retrieve plugin custom parameters
        if (this.selector.nodeType === 1 && this.selector.tagName.toLowerCase() === 'img') {
            this.target = this.selector;
        } else {
            var children = this.selector.childNodes;
            for(var i = 0, l=children.length; i<l; ++i) {
                var child = children[i];
                if(child.nodeType === 1 && child.tagName.toLowerCase() === 'img') this.target = child;
            }
        }
        this.img = this.target.src;
        this.param = this.target.hasAttribute('gr-lazyloader-src') ? this.target.getAttribute('gr-lazyloader-src').split(',') : [];

        // webp support?
        if ('srcset' in document.createElement('img') && typeof window.devicePixelRatio !== 'undefined') {

            updateWindowSize();

            var image = new Image();
            image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEADMDOJaQAA3AA/uuuAAA=';
            image.onload = image.onerror = function () {
                hasWebPSupport = image.width === 2;
                var requestAnimationFrameFunction = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
                hasChange = true;

                var reInitIfHasChange = function () {
                    if (hasChange) {
                        hasChange = false;
                        _this.init();
                    }
                    requestAnimationFrameFunction.call(null, reInitIfHasChange);
                };
                reInitIfHasChange();
            }
        }

        this.init();
    }

    // Methods

    grLazyLoader.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            // Bind all event handlers for referencability
            arr = ['resizeHandler', 'scrollHandler', 'loadHandler'];
            for (var i = 0, len = arr.length; i < len; i++) {
                var method = arr[i];
                this[method] = this[method].bind(this);
            }
            window.addEventListener('resize', this.resizeHandler);
            //window.addEventListener('scroll', this.scrollHandler);
            //window.addEventListener('load', this.loadHandler);

            //this.updateParentNodesScrollListeners();

            // Flag the plugin as ready
            this.ready = true;
            this.selector.setAttribute('gr-lazyloader', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + this.img + '): ready');

            this.displayBest();
        }
    }

    grLazyLoader.prototype.displayBest = function displayBest() {

        var _this = this;

        var updateSource = function (container, element) {
            return new Promise(function(resolve, reject) {
                var options = _this.param;
                var temp = [];
                var optionsCount = options.length;
                for (var j = 0; j < optionsCount; j++) {
                    var option = options[j].trim();
                    if (option.length === 0) {
                        continue;
                    }
                    var spaceIndex = option.lastIndexOf(' ');
                    if (spaceIndex === -1) {
                        var optionImage = option;
                        var optionWidth = 999998;
                    } else {
                        var optionImage = option.substr(0, spaceIndex);
                        var optionWidth = !isNaN(parseInt(global.sassBreakpoints[option.substr(spaceIndex + 1, option.length - spaceIndex)], 10)) ? parseInt(global.sassBreakpoints[option.substr(spaceIndex + 1, option.length - spaceIndex)], 10) : parseInt(option.substr(spaceIndex + 1, option.length - spaceIndex - 3), 10);
                    }
                    var add = false;
                    if (optionImage.indexOf('.webp', optionImage.length - 5) !== -1) {
                        if (hasWebPSupport) add = true;
                    } else {
                        add = true;
                    }
                    if (add) temp.push([optionImage, optionWidth]);
                }
                temp.sort(function (a, b) {
                    if (a[1] < b[1]) return -1;
                    if (a[1] > b[1]) return 1;
                    if (a[1] === b[1]) {
                        if (b[0].indexOf('.webp', b[0].length - 5) !== -1) return 1;
                        if (a[0].indexOf('.webp', a[0].length - 5) !== -1) return -1;
                    }
                    return 0;
                });
                options = temp;
                var containerWidth = container.offsetWidth * window.devicePixelRatio;

                var bestSelectedOption = null;
                var optionsCount = options.length;
                for (var j = 0; j < optionsCount; j++) {
                    var optionData = options[j];
                    if (optionData[1] >= containerWidth) {
                        bestSelectedOption = optionData;
                        break;
                    }
                }

                if (bestSelectedOption === null) bestSelectedOption = [element.getAttribute('src'), 999999];

                if (typeof container.lastSetOption === 'undefined') container.lastSetOption = ['', 0];

                if (container.lastSetOption[1] < bestSelectedOption[1]) {
                    global.methods.removeAttributeValue(container, 'gr-lazyloader', 'done');
                    global.methods.toggleAttributeValue(container, 'gr-lazyloader', 'processing');
                    var fireEvent = container.lastSetOption[1] === 0;
                    var url = bestSelectedOption[0];
                    var image = new Image();
                    image.setAttribute('srcset', url);
                    image.addEventListener('load', function () {
                        element.setAttribute('srcset', url);
                        resolve(url);
                        // if (fireEvent) {
                        //     var handler = container.getAttribute('data-onlazyload');
                        //     if (handler !== null) (new Function(handler).bind(container))();
                        // }
                    }, false);
                    image.addEventListener('error', function () {
                        container.lastSetOption = ['', 0];
                        reject('grLazyLoader has failed due to an error');
                    }, false);
                    image.src = url;
                    container.lastSetOption = bestSelectedOption;
                }
            });
        };

        var update = function (element, unknownHeight) {
            var container = unknownHeight ? element : element.parentNode;

            if (global.methods.isVisible(container) && container.getAttribute("gr-lazyloader")=="inview") {
                updateSource(container, element)
                .then(function(url) {
                    global.methods.removeAttributeValue(container, 'gr-lazyloader', 'processing');
                    global.methods.toggleAttributeValue(container, 'gr-lazyloader', 'done');
                    if (groots.verbose) console.log(pluginName + '(' + url + '): done');
                });
            } else {
                updateSource(container, element)
                .then(function(url) {
                    global.methods.removeAttributeValue(container, 'gr-lazyloader', 'processing');
                    global.methods.toggleAttributeValue(container, 'gr-lazyloader', 'done');
                    if (groots.verbose) console.log(pluginName + '(' + url + '): done');
                });
            }
        };

        if (this.selector.tagName.toLowerCase() === 'img') {
            update(this.target, true);
        } else {
            update(this.target, false);
        }
    };

    grLazyLoader.prototype.updateParentNodesScrollListeners = function updateParentNodesScrollListeners(event) {
        var elements = document.querySelectorAll('[gr-lazyloader]');
        var elementsCount = elements.length;
        for (var i = 0; i < elementsCount; i++) {
            var parentNode = elements[i].parentNode;
            while (parentNode && parentNode.tagName.toLowerCase() !== 'html') {
                if (typeof parentNode.responsivelyLazyScrollAttached === 'undefined') {
                    parentNode.responsivelyLazyScrollAttached = true;
                    parentNode.addEventListener('scroll', setChanged);
                }
                parentNode = parentNode.parentNode;
            }
        }
    };

    grLazyLoader.prototype.resizeHandler = function resizeHandler(event) {
        updateWindowSize();
        setChanged();
        this.displayBest();
    };

    grLazyLoader.prototype.scrollHandler = function scrollHandler(event) {
        setChanged();
    };

    grLazyLoader.prototype.loadHandler = function loadHandler(event) {
        setChanged();
    };

    // Destroy - remove listeners to prevent from memory leak
    grLazyLoader.prototype.destroy = function destroy() {
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('load', this.loadHandler);
        this.ready = false;
        global.methods.removeAttributeValue(this.selector, 'gr-lazyloader', 'done processing ready');
        if (groots.verbose) console.log(pluginName + ': destroyed');
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function () {
            return this.each(function(index) {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grLazyLoader(this, index));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grLazyLoader;
    // Export to Browser
    else global[pluginName] = grLazyLoader; // eslint-disable-line

}( window.groots ));
