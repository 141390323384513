/*!
* jQuery Custom-form-elements plugin
* Author: Globalia
* Licensed under the WTFPL license
*/



( function( global ) { // eslint-disable-line

    "use strict";

    var pluginName = 'grFormUpload';

    // Constructor

    function grFormUpload(selector) {
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;
        this.init();
    }
    // Methods

    grFormUpload.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            // Bind all event handlers for referencability
            var arr = ['changeHandler'];
            for (var i = 0, len = arr.length; i < len; i++) {
                var method = arr[i];
                this[method] = this[method].bind(this);
            }
            window.addEventListener('change', this.changeHandler);

            // Flag the plugin as ready
            this.ready = true;
            this.selector.setAttribute('gr-form-upload', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + this.selector.id + '): ready');

            this.build();
        }
    };

    grFormUpload.prototype.build = function build(event) {
        this.state = 'processing';
        global.methods.toggleAttributeValue(this.selector, 'gr-form-upload', this.state);
        if (groots.verbose) console.log(pluginName + '(' + this.selector.id + '): '+ this.state);

        var fake_label = document.createElement('label');
        fake_label.setAttribute('for', this.selector.id);
        fake_label.innerHTML = '<span></span>';

        var collection = [this.selector, fake_label];
        var wrapper = document.createElement('div');
        wrapper.className = 'gr-form-upload__wrapper';

        global.methods.wrapAll(collection, wrapper);

        global.methods.removeAttributeValue(this.selector, 'gr-form-upload', this.state);
        this.state = 'done';
        global.methods.toggleAttributeValue(this.selector, 'gr-form-upload', this.state);
        if (groots.verbose) console.log(pluginName + '(' + this.selector.id + '): '+ this.state);
    };

    grFormUpload.prototype.changeHandler = function changeHandler(event) {
        //this.fill();
        //console.log(pluginName + ': changed');

        if (this.selector.value != '') {
            var filename = this.selector.value.split('\\');
            $(this.selector.nextElementSibling.children).text(filename.slice(-1)[0]);
        }
    };

    // Avoid Plugin.prototype conflicts
    /*$.extend( Plugin.prototype, {
        init: function() {
            var instance = this;

            instance.$element.next('label').addBack().wrapAll('<div class="' + instance.settings.class + '__wrapper"></div>');
            // IE- il faut trigger un click sur l'input, pour que le component fonctionne au one click au lieu du double click.
        },
        update: function() {
            var instance = this;

            if( instance.$element.val()!=='' ) {
                instance.$element.next('label').html('<div>' + instance.$element.val().replace("C:\\fakepath\\", "") + '</div>').addClass('is-ready');
            }
        },
        destroy: function() {
            this.$element.unwrap();
        },
        callFunction: function (call, options) {
            if ( typeof this[call] === 'function' ) {
                return this[call](options);
            }
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function( call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options || call ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        } );
    };

    $(function () {
        $(document).find('[gr-form] input[type=file]')
        .customUpload()
        .on('change', function () {
            $(this).customUpload('update');
        });
    });

} )( require('jquery'), window, document );*/

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grFormUpload(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grFormUpload;
    // Export to Browser
    else global[pluginName] = grFormUpload; // eslint-disable-line

}( window.groots ));
