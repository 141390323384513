/*!
 * jQuery Custom-form-elements plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */

// Custom Select
// --------------------------------------------------

( function( $, global ) {

    'use strict';

    var pluginName = 'grFormSelect',
        defaults = {
            class: 'gr-select',
            maxHeight: '250px'
        };

    // Constructor

    function grFormSelect(element, options) {
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;

        this.$element = $(element);
        this.$container = '';
        this.height = '';

        this.init();

        // this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;
        // this.init();
    }

    // Methods

    grFormSelect.prototype.init = function init() {
        var instance = this;

        instance.$element.wrap('<div class="' + instance.settings.class + '"></div>');
        instance.$element.after('<div class="' + instance.settings.class + '__box"></div>');

        instance.$container = instance.$element.parent();

        instance.build(false);
    };

    grFormSelect.prototype.build = function build(rebuild) {
        var instance = this;

        if(instance.element.hasAttribute('disabled')) {
            instance.$container.addClass('disabled');
        }

        // Create custom selected box
        var $styledSelect = instance.$element.next('.' + instance.settings.class + '__box');
        $styledSelect.html('<div>' + instance.$element.children('option:selected').eq(0).text() + '</div>');

        // Unbind change event before rebuild
        if (rebuild) instance.$element.unbind('change');

        // Bind change event on real select to update the custom one
        instance.$element.on('change', function() {
            var val = instance.$element[0].options[this.selectedIndex].text;
            $styledSelect.html('<div>' + val + '</div>').removeClass('is-active').addClass('is-selected');
        });

        // Create custom option list and insert it right after our custom select box
        var $list = $('<ul />', {'class': instance.settings.class + '__options'}).insertAfter($styledSelect);

        // If there is option groups, loop through each one
        for (var i = 0; i < instance.$element.children('optgroup').length; i++) {
            var $currentGroup = instance.$element.children('optgroup').eq(i);

            // Create custom option group title, and add it to the custom option list
            var $group = $('<li class="' + instance.settings.class + '__optgroup" disabled="disabled"><span>' + $currentGroup.prop('label') + '</span></li>').appendTo($list);

            // Create custom option group list
            var $groupItems = $('<ul></ul>').appendTo($group);

            // Fill the custom option group list with data from the real select
            for (var j = 0; j < $currentGroup.children('option').length; j++) {
                $('<li />', {
                    text: $currentGroup.children('option').eq(j).text(),
                    rel: $currentGroup.children('option').eq(j).val(),
                    disabled: $currentGroup.children('option').eq(j).prop('disabled')
                }).appendTo($groupItems);
            }
        }

        // Loop through each options and add them to the custom option list
        for (var k = 0; k < instance.$element.children('option').length; k++) {
            $('<li />', {
                text: instance.$element.children('option').eq(k).text(),
                rel: instance.$element.children('option').eq(k).val(),
                class: instance.$element.children('option').eq(k).attr('data-country-id') ? instance.$element.children('option').eq(k).attr('data-country-id') : ''
            }).appendTo($list);


        }

        // Get height of the custom option list
        instance.height = $list[0].scrollHeight + 1; // + 1 pixel in case we have border

        // Set max height
        if ( instance.height > parseInt(instance.settings.maxHeight, 10) ) {
            instance.height = instance.settings.maxHeight;
        }

        // Unbind click event before rebuild
        if (rebuild) $styledSelect.unbind('click');

        // Bind click event on the custom selected box
        $styledSelect.on('click', function(e) {
            e.stopPropagation();

            if ( instance.$container.hasClass('is-active') ) {
                instance.$container.removeClass('is-active');
                $list.removeAttr('style');
            } else {
                // Calculate if the option list has enough space to expand bewteen end of viewport and our select box
                var spaceBottom = $(window).outerHeight() - ( $(this).offset().top - $(document).scrollTop() ) - $(this).outerHeight();

                // Invert open direction if there is not enough space
                if ( spaceBottom < instance.height ) {
                    instance.$container.addClass('is-inverted');
                } else {
                    instance.$container.removeClass('is-inverted');
                }

                // Close all other open custom select
                $('.' + instance.settings.class + '.is-active').not(this).each(function() {
                    $(this).removeClass('is-active');
                    $(this).find('.' + instance.settings.class + '__options').removeAttr('style');
                });

                instance.$container.addClass('is-active');

                $list.css('height', instance.height);
            }
        });

        // Bind click event on each option, trigger change event and switch classes
        $list.find('li').each( function() {
            $(this).on('click', function(e) {
                e.stopPropagation();

                if( !$(this)[0].hasAttribute("disabled") ) {
                    $styledSelect.text($(this).text()).removeClass('is-active').addClass('is-selected');
                    instance.$element.val($(this).attr('rel'));
                    instance.$element.eq($(this).index()).prop('selected',true);
                    instance.$element.trigger('change');
                    instance.$container.removeClass('is-active');
                    $list.removeAttr('style');
                    $(this).closest('.parsley-error').removeClass('parsley-error').find('.parsley-errors-list').remove();
                }
            });
        });

        // Close select if the user click outside
        $(document).click(function() {
            instance.$container.removeClass('is-active');
            $list.removeAttr('style');
        });

        // Close select if the user hit the escape key
        $(document).keyup(function(e) {
            if (e.keyCode == 27) {
                instance.$container.removeClass('is-active');
                $list.removeAttr('style');
            }
        });
    };

    grFormSelect.prototype.rebuild = function rebuild() {
        var instance = this;

        instance.$container.find('.' + instance.settings.class + '__box').empty();
        instance.$container.find('.' + instance.settings.class + '__options').remove();
        instance.build(true);
    };

    grFormSelect.prototype.destroy = function destroy() {
        var instance = this;

        instance.$container.find('.' + instance.settings.class + '__box').remove();
        instance.$container.find('.' + instance.settings.class + '__options').remove();
        instance.$element.unwrap();
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grFormSelect(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grFormSelect;
    // Export to Browser
    else global[pluginName] = grFormSelect; // eslint-disable-line

}( require('jquery'), window.groots ));
