// -------------------------------------------
// JavaScript Video Background
// Author: Globalia
// Licensed under the WTFPL license
// -------------------------------------------
// ...


( function( global ) { // eslint-disable-line

    var pluginName = 'grVideoYoutube';

    var loadAPI = function loadAPI(callback) {

        // Load Youtube API
        var tag = document.createElement('script'),
            head = document.getElementsByTagName('head')[0];

        if (window.location.origin == 'file://') tag.src = 'http://www.youtube.com/iframe_api';
        else tag.src = '//www.youtube.com/iframe_api';

        head.appendChild(tag);

        // Clean up Tags.
        head = null;
        tag = null;

        iframeIsReady(callback);
    };

    var iframeIsReady = function iframeIsReady(callback) {
        // Listen for Gobal YT player callback
        if (typeof YT === 'undefined' && typeof window.loadingPlayer === 'undefined') {
            // Prevents Ready Event from being called twice
            window.loadingPlayer = true;

            // Creates deferred so, other players know when to wait.
            window.dfd = $.Deferred();
            window.onYouTubeIframeAPIReady = function() {
                window.onYouTubeIframeAPIReady = null;
                window.dfd.resolve( "done" );
                callback();
            };
        } else if (typeof YT === 'object')  {
            callback();
        } else {
            window.dfd.done(function( name ) {
                callback();
            });
        }
    };

    // Constructor

    function grVideoYoutube(selector) {

        // Create global references
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;
        this.$node = $(this.selector);

        // Set default parameters
        this.player = null;
        this.breakpoint = 0;
        this.options = {
            ratio: 16 / 9,
            videoId: 'NqzE4SiBlTw',
            mute: true,
            repeat: true,
            width: $(window).width(),
            start: 0,
            pauseOnScroll: true,
            fitToBackground: true,
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                iv_load_policy: 3,
                modestbranding: 1,
                autoplay: 0,
                controls: 0,
                showinfo: 0,
                wmode: 'opaque',
                branding: 0,
                autohide: 0,
                rel: 0
            },
            events: null
        };

        // Retrieve plugin custom parameters
        this.param = this.selector.getAttribute('gr-video-youtube').split(' ');
        this.map = Object.keys(global.sassBreakpoints);

        // Overwrite default parameters with custom parameters
        for (var i = 0, len = this.param.length; i < len; i++) {
            var attr = this.param[i];
            if (this.map.contains(attr)) this.breakpoint = parseInt(global.sassBreakpoints[attr], 10);
            else this.options.videoId = attr;
        }

        this.init();
    };

    // Methods

    grVideoYoutube.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            var self = this;

            // Bind all event handlers for referencability
            var arrHandler = ['resizeHandler', 'scrollHandler'];
            for (var i = 0, len = arrHandler.length; i < len; i++) {
                var method = arrHandler[i];
                this[method] = this[method].bind(this);
            }

            window.addEventListener('resize', this.resizeHandler);
            window.addEventListener('scroll', this.scrollHandler);

            // Setup event defaults with the reference to this
            self.options.events = {
                'onReady': function(e) {
                    global.methods.removeAttributeValue(self.selector, 'gr-video-youtube', 'processing');
                    self.onPlayerReady(e);

                    // Callback for when finished
                    if (typeof self.options.callback == 'function') self.options.callback.call(this);
                },
                'onStateChange': function(e) {
                    if (e.data === 1) {
                        var values = self.selector.getAttribute('gr-video-youtube').split(' ');
                        var existingIndex = values.indexOf('done');
                        if (existingIndex < 0)  values.push('done');
                        self.selector.setAttribute('gr-video-youtube', values.join(' '));
                    } else if (e.data === 0 && self.options.repeat) { // video ended and repeat option is set true
                        self.player.seekTo(self.options.start);
                    }
                }
            }

            //self.options = $.extend(true, {}, self.defaults, self.userOptions);
            self.options.height = Math.ceil(self.options.width / self.options.ratio);

            self.createContainerVideo(self.options.fitToBackground);

            loadAPI(self.onYouTubeIframeAPIReady.bind(self));

            // Flag the plugin as ready
            self.ready = true;
            global.methods.toggleAttributeValue(self.selector, 'gr-video-youtube', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + self.selector.options.videoId + '): ready');

            self.resizeHandler();
        }
    };

    grVideoYoutube.prototype.createContainerVideo = function createContainerVideo(bg) {
        var self = this;
        var $YTPlayerString = $('<div gr-video-wrapper id="ytWrapper__' + self.options.videoId + '"><div gr-video-player id="ytPlayer__' + self.options.videoId + '"></div></div>');

        self.$node.append($YTPlayerString);
        self.$YTPlayerString = $YTPlayerString;

        $YTPlayerString = null;
    };

    grVideoYoutube.prototype.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
        var self = this;
        global.methods.toggleAttributeValue(self.selector, 'gr-video-youtube', 'processing');
        self.player = new window.YT.Player('ytPlayer__' + self.options.videoId, self.options);
    };

    grVideoYoutube.prototype.onPlayerReady = function onPlayerReady(event) {
        var self = this;
        if (self.options.mute) event.target.mute();
        if (global.methods.isVisible(self.selector)) {
            self.ratioP = self.player.a.clientWidth / self.player.a.clientHeight;
            event.target.playVideo();
        }
    };

    grVideoYoutube.prototype.getPlayer = function getPlayer() {
        return this.player;
    };

    grVideoYoutube.prototype.resizeHandler = function resizeHandler() {
        var self = this;
        var container = $('[gr-video-wrapper]');
         //if (!self.options.fitToBackground) container = self.$node;

        var width = container.width(),
            pWidth, // player width, to be defined
            height = container.height(),
            pHeight, // player height, tbd
            $YTPlayerPlayer = $('#ytPlayer__' + self.options.videoId),
            ratioW = container.width() / container.height();

        // when screen aspect ratio differs from video, video must center and underlay one dimension
        if (width / self.ratioP < height) {
            pWidth = height * self.ratioP;
            $YTPlayerPlayer.height(height).width(pWidth);
        } else { // new video width < window width (gap to right)
            pHeight = width / self.ratioP;
            $YTPlayerPlayer.width(width).height(pHeight);
        }

        $YTPlayerPlayer = null;
        container = null;
    };

    grVideoYoutube.prototype.scrollHandler = function scrollHandler() {
        var self = this;
        if (global.methods.isVisible(self.selector) && self.options.pauseOnScroll) {
            var state = self.player.getPlayerState();
            if (state === 1) self.player.pauseVideo();
            clearTimeout( $.data( self, "scrollCheck" ) );
            $.data( self, "scrollCheck", setTimeout(function() {
                var state = self.player.getPlayerState();
                //console.log(state);
                if (state === 0 || state === 2 || state === 3 || state === 5) self.player.playVideo();
            }, 250) );

        } else if (self.options.pauseOnScroll) {
            self.player.pauseVideo();
        }
    }

    // Destroy - remove listeners to prevent from memory leak
    // grPlugin.prototype.destroy = function destroy() {
    //     this.ready = 'false';
    //     removeAttributeValue(this.selector, 'gr-svg-inliner', 'ready processing done');
    //     if (groots.verbose) console.log(pluginName + ': destroyed');
    // };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function () {
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grVideoYoutube(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grVideoYoutube;
    // Export to Browser
    else global[pluginName] = grVideoYoutube; // eslint-disable-line

}( window.groots ));
