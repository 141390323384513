( function( global, $ ) {

    $('.js-product').get(0) && refreshSelectedProductAttributes($('.js-product').first());

    $('.js-product').on('click', function(event) {
        event.preventDefault();
        refreshSelectedProductAttributes($(this));

        $(this).parent().siblings().removeClass('gr-active');
        $(this).parent().addClass('gr-active');
    });

    function refreshSelectedProductAttributes(product) {
        var productIndex = product.data('product-index');

        // Hide all products attributes and remove active tabs
        $('.js-product-attribute').hide().children('a').attr('gr-acc-tabs-trigger', '').removeClass('gr-active');

        // Show selected product attributes
        $('.js-product-' + productIndex).show();

        var $firstAttributeTab = $('.js-product-' + productIndex).first();

        if ($('.js-product-' + productIndex).children('li > a.gr-active').length == 0) {
            $firstAttributeTab.children('[gr-acc-tabs-trigger]').first().attr('gr-acc-tabs-trigger', 'active').addClass('gr-active');
            $firstAttributeTab.children('[gr-acc-tabs-target]').first().attr('gr-acc-tabs-target', 'active').addClass('gr-active');
        }
        refreshContainerHeight($firstAttributeTab);
    }

    function refreshContainerHeight($firstAttributeTab) {
        var $container = $firstAttributeTab.closest('ul').first();

        var spacing = 0;
        var offset = $container.find('.tab__title').height();

        $firstAttributeTab.find('.tab').each(function(){
            var n_offset = ($(this).prev('.tab').find('.tab-link').length) ? $(this).prev('.tab').find('.tab-link').outerHeight() : offset;
            offset += n_offset;

            $(this).find('.tab-link').css({top: offset + spacing});
            spacing = spacing + 10;
        });
    }

    $(window).on('load', function () {
        if (window.location.hash) {
            var priceListHash = window.location.hash.substring(1);
            if (priceListHash === 'plist') {
                var target = document.querySelector('a[href="#' + priceListHash + '"]');
                target.click();
            }
        }
    });

}( window.groots, jQuery ));
