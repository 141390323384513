(function($){

    $('.selection').on('click', 'input[type=checkbox]', function(e) {
        var form = $('#download-form');
        var strToToggle = $(this).next('label').html();

        $(this).next('label').html($(this).next('label').attr('alt-text'));
        $(this).next('label').attr('alt-text', strToToggle);
        $(this).closest('.card--gallery').toggleClass('selected');

        if($(this).prop('checked')){
            form.append('<input id="checkedFile-'+$(this).val()+'" type="hidden" name="files[]" value="'+ $(this).val() +'" />');
        }
        else{
            $('#checkedFile-' + $(this).val() ).remove();
        }

        if ($('[name="files[]"]').length <= 0)
            $('.counter .btn--primary').addClass('disabled');
        else
            $('.counter .btn--primary').removeClass('disabled');

        $('.counter').find('span:first-child').attr('data-counter', $('[name="files[]"]').length);

    });



})(jQuery);
