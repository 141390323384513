(function(global) { // eslint-disable-line
    "use strict";

    /**
     * Represents a PasswordReveal.
     * @constructor
     * @param {object} options - PasswordReveal options
     */
    function PasswordReveal(options) {

        // Default options
        this.options = {
            attribute: 'gr-password-reveal'
        };

        // Basic override
        for (var prop in options) {
            if (options.hasOwnProperty(prop)) {
                this.options[prop] = options[prop];
            }
        }

        this.isVisible = false;

        this.elToggle = this.options.toggle;
        this.passwordId = this.elToggle.getAttribute(this.options.attribute);
        this.elPasswordField = document.querySelector('#' + this.passwordId);
        this.elToggle.addEventListener('click', this.onToggle.bind(this));
    }

    /**
     * onToggle handler - on password reveal toggle click
     */
    PasswordReveal.prototype.onToggle = function() {
        var type = this.isVisible ? 'password' : 'text';
        this.isVisible = type == 'text';
        this.elPasswordField.setAttribute('type', type);
    }

    // reveals initialisation
    var instances = [];
    var classAttribute = 'gr-password-reveal';
    var elems = document.querySelectorAll('[' + classAttribute + ']');
    for (var i = 0, l = elems.length; i < l; i++) {
        var instance = new PasswordReveal({
            attribute: classAttribute,
            toggle: elems[i]
        });
        instances.push(instance);
    };

}(window.groots));