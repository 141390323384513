// -------------------------------------------
// JavaScript Responsive Navigation
// Author: Globalia
// Licensed under the WTFPL license
// -------------------------------------------
// Toggle behavior-attached classes on navigation


( function( global ) { // eslint-disable-line

    var pluginName = 'grNavigation';



    // Constructor

    function grNavigation(selector, options) {

        // Create global references
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        // Set default parameters

        this.config = {
            trigger: '[gr-nav-trigger]',
            target: '[gr-nav-panel]',
            subtrigger: '[gr-subnav-trigger]',
            subtarget: '[gr-subnav-panel]'
        }

        // Define calllbacks

        // Create overlay
        var overlay = document.createElement('div');
        overlay.setAttribute('gr-overlay', '');
        document.body.appendChild(overlay);
        this.overlay = overlay;

        // Init grPlugin
        this.init();
    }

    // Methods

    grNavigation.prototype.init = function init() {

        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            // Bind all event handlers for referencability
            arr = ['resizeHandler', 'mouseclickHandler', 'keyupHandler'];
            for (var i = 0, len = arr.length; i < len; i++) {
                var method = arr[i];
                this[method] = this[method].bind(this);
            }
            window.addEventListener('resize', this.resizeHandler);
            document.addEventListener('click', this.mouseclickHandler);
            document.addEventListener('keyup', this.keyupHandler);

            // Flag the plugin as ready
            this.ready = true;
            global.methods.toggleAttributeValue(this.selector, 'gr-nav', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + this.selector.id + '): ready');
        }
    }

    grNavigation.prototype.resizeHandler = function resizeHandler(event) {
        global.methods.removeAttributeValue(this.selector, 'gr-nav', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.trigger), 'gr-nav-trigger', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.target), 'gr-nav-panel', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtrigger), 'gr-subnav-trigger', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtarget), 'gr-subnav-panel', 'active');
        global.methods.removeAttributeValue(this.overlay, 'gr-overlay', 'visible');
    };

    grNavigation.prototype.mouseclickHandler = function mouseclickHandler(event) {
        if ( event.target.hasAttribute('gr-nav-trigger') || event.target.parentNode.hasAttribute('gr-nav-trigger') ) {
            event.preventDefault();
            var trigger = event.target;
            if(event.target.parentNode.hasAttribute('gr-nav-trigger')) trigger = event.target.parentNode;
            global.methods.toggleAttributeValue(this.selector, 'gr-nav', 'active');
            global.methods.toggleAttributeValue(trigger, 'gr-nav-trigger', 'active');
            global.methods.toggleAttributeValue(this.selector.querySelector('[gr-nav-panel]'), 'gr-nav-panel', 'active');

            Array.prototype.forEach.call(this.selector.querySelectorAll('[gr-subnav-trigger]'), function(el) {
                global.methods.removeAttributeValue(el, 'gr-subnav-trigger', 'active');
            });
            Array.prototype.forEach.call(this.selector.querySelectorAll('[gr-subnav-panel]'), function(el) {
                global.methods.removeAttributeValue(el, 'gr-subnav-panel', 'active');
            });

            global.methods.toggleAttributeValue(this.overlay, 'gr-overlay', 'visible');
        } else if( event.target.hasAttribute('gr-subnav-trigger') || event.target.parentNode.hasAttribute('gr-subnav-trigger') ) {
            event.preventDefault();
            var trigger = event.target;
            if(event.target.parentNode.hasAttribute('gr-subnav-trigger')) trigger = event.target.parentNode;
            global.methods.toggleAttributeValue(trigger, 'gr-subnav-trigger', 'active', true);
            global.methods.toggleAttributeValue(trigger.nextElementSibling, 'gr-subnav-panel', 'active', true);
        } else if( event.target == this.overlay ) {
            global.methods.removeAttributeValue(this.selector, 'gr-nav', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.trigger), 'gr-nav-trigger', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.target), 'gr-nav-panel', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtrigger), 'gr-subnav-trigger', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtarget), 'gr-subnav-panel', 'active');
            global.methods.removeAttributeValue(this.overlay, 'gr-overlay', 'visible');
        };
    };

    grNavigation.prototype.keyupHandler = function keyupHandler(event) {
        event.preventDefault();
        if ( event.keyCode == 27 ) {
            global.methods.removeAttributeValue(this.selector, 'gr-nav', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.trigger), 'gr-nav-trigger', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.target), 'gr-nav-panel', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtrigger), 'gr-subnav-trigger', 'active');
            global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtarget), 'gr-subnav-panel', 'active');
            global.methods.removeAttributeValue(this.overlay, 'gr-overlay', 'visible');
        }
    };

    // Destroy - remove listeners to prevent from memory leak
    grNavigation.prototype.destroy = function destroy() {
        window.removeEventListener('resize', this.resizeHandler);
        document.removeEventListener('click', this.mouseclickHandler);
        document.removeEventListener('keyup', this.keyupHandler);
        global.methods.removeAttributeValue(this.selector, 'gr-nav', 'ready active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.trigger), 'gr-nav-trigger', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.target), 'gr-nav-panel', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtrigger), 'gr-subnav-trigger', 'active');
        global.methods.removeAttributeValue(this.selector.querySelector(this.config.subtarget), 'gr-subnav-panel', 'active');
        global.methods.removeAttributeValue(this.overlay, 'gr-overlay', 'visible');
        if (groots.verbose) console.log(pluginName + ': destroyed');
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grNavigation(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grNavigation;
    // Export to Browser
    else global[pluginName] = grNavigation; // eslint-disable-line

}( window.groots ));
