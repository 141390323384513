( function( global, $ ) {

    var $search = $('[gr-search]'),
        $searchTrigger = $('[gr-search-trigger]');

    $searchTrigger.on('click', function(e) {
        // if (window.innerWidth > parseInt(window.groots.sassBreakpoints['md'], 10)) {
            e.preventDefault();

            if ( $(this).parent().attr('gr-search') === 'active') {
                $(this).parent().attr('gr-search', '');
                $(this).parent().find('input[type="search"]').blur();
                setTimeout(function() {
                    $("body").removeClass("search-is-open");
                }, 200);

            } else {
                // global.methods.toggleAttributeValue( $(this).parent()[0], 'gr-search', 'active', true);
                $(this).parent().attr('gr-search', 'active');
                $(this).parent().find('input[type="search"]').focus();
                $('body').addClass('search-is-open');
            }
        // }
    });




}( window.groots, jQuery ));
