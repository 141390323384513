// -------------------------------------------
// JavaScript Accordion/Tabs
// Author: Globalia
// Licensed under the WTFPL license
// -------------------------------------------
// ...


( function( global ) { // eslint-disable-line

    var pluginName = 'grAccTabs';

    // Constructor

    function grAccTabs( selector ) {

        // Create global references
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        // Set default parameters

        // Define calllbacks
        this.onOpenTab = function () {};
        this.onCloseTab = function () {};

        // Init grPlugin
        this.init();
    }

    grAccTabs.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            // Bind all event handlers for referencability
            arr = ['mouseclickHandler'];
            for (var i = 0, len = arr.length; i < len; i++) {
                var method = arr[i];
                this[method] = this[method].bind(this);
            }
            this.selector.addEventListener('click', this.mouseclickHandler);

            // Flag the plugin as ready
            this.ready = true;
            global.methods.toggleAttributeValue(this.selector, 'gr-acc-tabs', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + this.selector + '): ready');
        }
    };

    grAccTabs.prototype.mouseclickHandler = function mouseclickHandler(event) {
        if ( event.target.hasAttribute('gr-acc-tabs-trigger') || event.target.parentNode.hasAttribute('gr-acc-tabs-trigger')) {
            var trigger = event.target;
            if(event.target.parentNode.hasAttribute('gr-acc-tabs-trigger')) trigger = event.target.parentNode;
            trigger.collapsable = false;
            event.preventDefault();
            event.stopPropagation();
            // if (trigger.getAttribute('gr-acc-tabs-trigger').trim() == 'active') trigger.collapsable = true;
            for (var i = this.selector.children.length - 1; i >= 0; i--) {
                global.methods.removeAttributeValue(this.selector.children[i].querySelector('[gr-acc-tabs-trigger]'), 'gr-acc-tabs-trigger', 'active', true);
                global.methods.removeAttributeValue(this.selector.children[i].querySelector('[gr-acc-tabs-target]'), 'gr-acc-tabs-target', 'active', true);
            }
            if (!trigger.collapsable) {
                global.methods.toggleAttributeValue(trigger, 'gr-acc-tabs-trigger', 'active', true);
                global.methods.toggleAttributeValue(trigger.nextElementSibling, 'gr-acc-tabs-target', 'active', true);
                this.onOpenTab();
            } else {
                this.onCloseTab();
            }
        }
    };

    // Destroy - remove listeners to prevent from memory leak
    grAccTabs.prototype.destroy = function destroy() {
        window.removeEventListener('resize', this.resizeHandler);
        global.methods.removeAttributeValue(this.selector, 'gr-equalizer', 'ready processing done');
        if (groots.verbose) console.log(pluginName + ': destroyed');
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grAccTabs(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grAccTabs;
    // Export to Browser
    else global[pluginName] = grAccTabs; // eslint-disable-line

}( window.groots ));
