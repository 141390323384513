// -------------------------------------------
// JavaScript Video Background
// Author: Globalia
// Licensed under the WTFPL license
// -------------------------------------------
// ...


( function( global ) { // eslint-disable-line

    var pluginName = 'grGoogleMap';

    var loadAPI = function loadAPI(options, callback) {

        // Load Youtube API
        var tag = document.createElement('script'),
            head = document.getElementsByTagName('head')[0];

        var lang = document.querySelector('html').getAttribute('lang');

        if (window.location.origin == 'file://') tag.src = 'https://maps.googleapis.com/maps/api/js?key=' + options.api_key + '&libraries=places&callback=googleMapsInitiated';
        else tag.src = '//maps.googleapis.com/maps/api/js?key=' + options.api_key + '&libraries=places&callback=googleMapsInitiated&language=' + lang;

        tag.async = true;
        head.appendChild(tag);

        // Clean up Tags.
        head = null;
        tag = null;

        apiIsReady(callback);
    };

    var apiIsReady = function apiIsReady(callback) {
        // Listen for Gobal YT player callback
        if (typeof window.google === 'undefined' && typeof window.loadingMap === 'undefined') {
            // Prevents Ready Event from being called twice
            window.loadingMap = true;

            // Creates deferred so, other players know when to wait.
            window.dfd_gm = $.Deferred();
            window.googleMapsInitiated = function() {
                window.googleMapsInitiated = null;
                window.dfd_gm.resolve( "done" );
                callback();
            };
        } else if (typeof window.google === 'object' && window.google,maps)  {
            callback();
        } else {
            window.dfd_gm.done(function( name ) {
                callback();
            });
        }
    };

    // Constructor

    function grGoogleMap(selector) {

        // Create global references
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        // Set default parameters
        this.options = {
            api_key: 'AIzaSyA-TNV32sNuG_dJN0sV9p6L37D_wj5iuz8',
            lat: 45.502834,
            lng: -73.513764,
            url: 'https://goo.gl/maps/kFc2hjWsvAP2',
            inactive_pin: '/dist/img/map/inactif.png',
            active_pin: '/dist/img/map/actif.png',
            title: '',
            mapType: 'roadmap',
            markers: [],
            map_options: {
                zoom: 15,
                //draggable: false,
                //styles: window.styleArray,
                center: {},
                scrollwheel: false,
                disableDoubleClickZoom: true
            },
            infoWindow: null,
            events: null
        };

        this.lat_lng = {};
        this.map = {};
        this.bounds = {};

        this.googleMapsIsInitialized = false;
        this.onReady = function () {};

        // Retrieve plugin custom parameters
        this.param = this.selector.getAttribute('gr-google-map').split(',');
        this.lat_lng.lat = parseFloat(this.param[0]);
        this.lat_lng.lng = parseFloat(this.param[1]);
        this.options.map_options.center = this.lat_lng;
        //this.map = Object.keys(global.sassBreakpoints);

        // Overwrite default parameters with custom parameters
        // for (var i = 0, len = this.param.length; i < len; i++) {
        //     var attr = this.param[i];
        //     if (this.map.contains(attr)) this.breakpoint = parseInt(global.sassBreakpoints[attr], 10);
        //     else this.options.videoId = attr;
        // }

        this.init();
    };

    // Methods

    grGoogleMap.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            var self = this;

            // Bind all event handlers for referencability
            var arrHandler = ['resizeHandler'];
            for (var i = 0, len = arrHandler.length; i < len; i++) {
                var method = arrHandler[i];
                this[method] = this[method].bind(this);
            }

            window.addEventListener('resize', this.resizeHandler);

            loadAPI(self.options, self.googleMapsInitiated.bind(self));

            // Flag the plugin as ready
            self.ready = true;
            global.methods.toggleAttributeValue(self.selector, 'gr-google-map', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + self.selector.options.videoId + '): ready');
        }
    };

    grGoogleMap.prototype.createMarker = function createMarker(options) {
        var self = this;
        if (options.lat == undefined && options.lng == undefined && options.position == undefined) {
            throw 'No latitude or longitude defined.';
        }

        var details = options.details,
            base_options = {
                position: new google.maps.LatLng(options.lat, options.lng),
                map: self.map,
                icon: self.options.inactive_pin
            },
            marker_options = {};

        global.methods.objectAssign(base_options, options);
        global.methods.objectAssign(marker_options, base_options);

        var marker = new google.maps.Marker(marker_options);

        if (options.infoWindow) marker.infoWindow = new google.maps.InfoWindow(options.infoWindow);

        google.maps.event.addListener(marker, 'click', function() {

            this.details = details;
            self.map.panTo(this.getPosition());

            for (var i = 0, marker; marker = self.options.markers[i]; i++){
                marker.setIcon(self.options.inactive_pin);
            }
            this.setIcon(self.options.active_pin);

            if (options.click) options.click.apply(this, [this]);

            if (options.infoWindow) {
                for (var i = 0, marker; marker = self.options.markers[i]; i++){
                    if (marker.infoWindow) marker.infoWindow.close();
                }
                this.infoWindow.open(self.map, this);
            }
        });


        return marker;
    };

    grGoogleMap.prototype.addMarker = function addMarker(options) {
        var self = this;

        var marker;
        if(options.hasOwnProperty('gm_accessors_')) {
            // Native google.maps.Marker object
            marker = options;
        }
        else {
            if ((options.hasOwnProperty('lat') && options.hasOwnProperty('lng')) || options.position) {
                marker = self.createMarker(options);
                this.bounds.extend (new window.google.maps.LatLng(options.lat, options.lng));
            }
            else {
                throw 'No latitude or longitude defined.';
            }
        }

        self.options.markers.push(marker);
        self.onDone();

        return marker;
    };

    grGoogleMap.prototype.addMarkers = function addMarkers( array ) {
        for (var i = 0, marker; marker=array[i]; i++) {
            this.addMarker(marker);
        }
        return this.markers;
    };


    grGoogleMap.prototype.googleMapsInitiated = function googleMapsInitiated() {
        var self = this;
        global.methods.toggleAttributeValue(self.selector, 'gr-google-map', 'processing');

        self.map = new google.maps.Map(self.selector, {
            zoom: 13,
            styles: window.styleArray,
            scrollwheel: false,
            disableDoubleClickZoom: true
        });

        self.map.setOptions(self.options.map_options);
        self.bounds = new window.google.maps.LatLngBounds();

        self.googleMapsIsInitialized = true;
        self.onReady();
    };

    grGoogleMap.prototype.resizeHandler = function resizeHandler() {
        var self = this;
        global.methods.toggleAttributeValue(self.selector, 'gr-google-map', 'processing');
        self.onDone();
    };

    grGoogleMap.prototype.onDone = function onDone() {
        var self = this;
        self.map.fitBounds(self.bounds);

        global.methods.removeAttributeValue(self.selector, 'gr-google-map', 'processing');
        global.methods.toggleAttributeValue(self.selector, 'gr-google-map', 'done');
    };

    // Destroy - remove listeners to prevent from memory leak
    // grPlugin.prototype.destroy = function destroy() {
    //     this.ready = 'false';
    //     removeAttributeValue(this.selector, 'gr-svg-inliner', 'ready processing done');
    //     if (groots.verbose) console.log(pluginName + ': destroyed');
    // };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function () {
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grGoogleMap(this));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grGoogleMap;
    // Export to Browser
    else global[pluginName] = grGoogleMap; // eslint-disable-line

}( window.groots ));
