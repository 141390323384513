/*!
 * jQuery Custom-form-elements plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */

// Custom Select
// --------------------------------------------------

( function( $, window, document ) {

    'use strict';

    var pluginName = 'formNormalize',
        defaults = {
            fieldClass: 'gr-form__field',
            grid: 'sm-1'
        };

    function Plugin ( element, options ) {
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;
        this.$element = $(element);

        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend( Plugin.prototype, {
        init: function() {
            var instance = this;

            for( var i in defaults ) {
                instance.settings[i] = instance.$element.attr('gr-form-normalize-'+i)  || instance.settings[i];
            }

            // Add grid row to form element and grid block to all fields
            if ( instance.element.tagName !== 'FORM' ) {
                instance.$element.find('form').attr('gr-grid', 'row--block ' + instance.settings.grid);

                instance.$element
                    .find('form > div:not([type="hidden"])')
                    .attr('gr-grid', 'block');
            } else {
                instance.$element.attr('gr-grid', 'row--block ' + instance.settings.grid);

                instance.$element
                    .find('> div:not([type="hidden"])')
                    .attr('gr-grid', 'block');
            }

            // Determine which generator is used, then normalize form
            if ( instance.$element.find('.hs-form-field').length ) {
                instance.normelizeHubspot();
            } else if ( instance.$element.find('.gfield').length ) {
                instance.normelizeGravity();
            }

            // Re-initialize form plugins
            if ( instance.$element.find('select').length && $.fn.customSelect !== undefined ) {
                instance.$element.find('select').customSelect();
            }

            if ( instance.$element.find('input[type="file"]').length && $.fn.customUpload !== undefined ) {
                $(document).find('[gr-form] input[type=file]')
                    .customUpload()
                    .on('change', function () {
                        $(this).customUpload('update');
                    });
            }

            // Form is ready
            instance.$element.attr('gr-form-normalize', 'is-ready');
        },
        normelizeGravity: function() {
            var instance = this;

            // Add form field class
            instance.$element
                .find('.gfield')
                .addClass(instance.settings.fieldClass);
        },
        normelizeHubspot: function() {
            var instance = this;

            // Add form field class
            instance.$element
                .find('.hs-form-field:not([class*="_radio"]), .hs-form-radio, .hs-form-checkbox')
                .addClass(instance.settings.fieldClass);

            // Make sure that checkbox and radio are positionned before their label
            instance.$element.find('input[type="checkbox"]').each(function() {
                var label = instance.$element
                                .find('label[for="' + $(this).attr('id') + '"]')
                                .filter('.hs-form-booleancheckbox-display, .hs-form-checkbox-display');

                $(this).insertBefore(label);
            });

            instance.$element.find('input[type="radio"]').each(function() {
                var label = instance.$element.find('label[for="' + $(this).attr('id') + '"].hs-form-radio-display');
                $(this).insertBefore(label);
            });

            // Make sure that file input labels are positionned after
            instance.$element.find('input[type="file"]').each(function() {
                var label = instance.$element.find('label[for="' + $(this).attr('id') + '"]');

                $(this).after(label);
            });
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    } );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, 'plugin_' + pluginName ) ) {
                $.data( this, 'plugin_' + pluginName, new Plugin( this, options ) );
            } else {
                return $.data(this, 'plugin_' + pluginName).callFunction(call, options || call);
            }
        } );
    };

    $(window).on('load', function(e) {
        $(document).find('[gr-form-normalize]').formNormalize();
    });

} )( require('jquery'), window, document );
