// -------------------------------------------
// JavaScript Equalizer
// Author: Globalia
// Licensed under the WTFPL license
// -------------------------------------------
// Equalize heights of targets above a breakpoint


( function( global ) { // eslint-disable-line

    var pluginName = 'grEqualizer';

    if (Array.prototype.contains === undefined) {
        // attach the .contains method to Array's prototype to call it on any array
        Array.prototype.contains = function (thing) {
            if (!this) return false; // if the other array is a falsy value, return false
            var result = false; // start by assuming the array doesn't contain the thing
            for (var i = 0, l=this.length; i < l; i++) {
                if (this[i] instanceof Array) { // if anything in the array is the thing then change our mind from before
                    if (this[i].equals(thing)) result = true;
                } else if (this[i]===thing) result = true;
            }
            return result; //return the decision
        }
    }

    // Constructor

    function grEqualizer( selector ) {

        // Create global references
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        // Set default parameters
        this.breakpoint = 0;
        this.targets = this.selector.children;

        // Retrieve plugin custom parameters
        this.param = this.selector.getAttribute('gr-equalizer').split(' ');
        this.map = Object.keys(global.sassBreakpoints);

        // Overwrite with custom parameters
        for (var i = 0, len = this.param.length; i < len; i++) {
            var attr = this.param[i];
            if (!isNaN(attr)) this.breakpoint = attr;
            if (this.map.contains(attr)) this.breakpoint = parseInt(global.sassBreakpoints[attr], 10);
            if (attr.charAt(0) == '.')  {
                this.targets = this.selector.querySelectorAll(attr);
                this.targetsSelector = attr;
            }
        }

        this.init();
    }

    grEqualizer.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            // Bind all event handlers for referencability
            arr = ['resizeHandler'];
            for (var i = 0, len = arr.length; i < len; i++) {
                var method = arr[i];
                this[method] = this[method].bind(this);
            }
            window.addEventListener('resize', this.resizeHandler);

            // Flag the plugin as ready
            this.ready = true;
            global.methods.toggleAttributeValue(this.selector, 'gr-equalizer', 'ready');
            if (groots.verbose) console.log(pluginName + '(' + this.selector + '): ready');

            this.getTargets();
        }
    };

    grEqualizer.prototype.reset = function reset() {
        // Re-init min-height on each target
        for (var t = 0; t < this.targets.length; t++) {
            this.targets[t].style.minHeight = 0;
        }
    };

    grEqualizer.prototype.equalize = function equalize(targetsArray) {
        var targetsTallest = 0;
        var targetsHeights = [0];

        for (var t = 0; t < targetsArray.length; t++) {
            if(targetsArray[t].style.borderWidth) console.log('warning, you\'re attempting to equalize at least a element with border');
            // Re-init min-height on each target
            targetsArray[t].style.minHeight = 0;
        }
        for (var t = 0; t < targetsArray.length; t++) {
            // Build array of heights
            targetsHeights.push(targetsArray[t].scrollHeight);
        }
        // Get highest target value fronm array and cache it
        targetsTallest = Math.max.apply(null, targetsHeights);

        // Apply highest target value to each target
        for (var t = 0; t < targetsArray.length; t++) {
            targetsArray[t].style.minHeight = targetsTallest + 'px';
        }
    };

    grEqualizer.prototype.getTargets = function getTargets() {
        global.methods.toggleAttributeValue(this.selector, 'gr-equalizer', 'processing');
        var targets = this.targetsSelector;
        this.rowsAtt = this.selector.getAttribute('gr-grid').split(' ');

        if(this.rowsAtt.contains('row--block')) {
            var index = this.rowsAtt.indexOf('row--block');
            this.rowsAtt.splice(index, 1);

            var bestOption = [];

            for (var i = 0; i < this.rowsAtt.length; i++) {
                var rowsDef = this.rowsAtt[i].split('-');
                if (rowsDef.length == 2) {

                    var rowItems = rowsDef[1];
                    var rowBp = parseInt(global.sassBreakpoints[rowsDef[0]], 10);

                    if (window.innerWidth >= rowBp && window.innerWidth >= this.breakpoint) {

                        var subarray = [].slice.call(this.selector.querySelectorAll(targets));
                        var arrays = [];

                        while (subarray.length > 0) arrays.push(subarray.splice(0, rowItems));
                        bestOption = arrays;
                    }
                }
            }

            if (bestOption.length)
                for (var i = 0, len = bestOption.length; i < bestOption.length; i++) {
                    this.equalize(bestOption[i]);
                }
            else
                this.reset();

        } else {

            if (window.innerWidth >= this.breakpoint)
                this.equalize(this.targets);
            else
                this.reset();
        }

        global.methods.removeAttributeValue(this.selector, 'gr-equalizer', 'processing');
        global.methods.toggleAttributeValue(this.selector, 'gr-equalizer', 'done');
        if (groots.verbose) console.log(pluginName + ': done');
    };

    grEqualizer.prototype.resizeHandler = function resizeHandler(event) {
        global.methods.removeAttributeValue(this.selector, 'gr-equalizer', 'done');
        this.getTargets();
    };

    // Destroy - remove listeners to prevent from memory leak
    grEqualizer.prototype.destroy = function destroy() {
        window.removeEventListener('resize', this.resizeHandler);
        global.methods.removeAttributeValue(this.selector, 'gr-equalizer', 'ready processing done');
        if (groots.verbose) console.log(pluginName + ': destroyed');
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grEqualizer(this, options));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grEqualizer;
    // Export to Browser
    else global[pluginName] = grEqualizer; // eslint-disable-line

}( window.groots ));
