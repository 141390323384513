( function( window, $, global ) {
    "use strict";

    var body = document.getElementsByTagName("body")[0],
        form = document.querySelector('[js-form-job-alert]'),
        alertElement = document.querySelector('[js-job-alert]');

    if (alertElement) {
        window.addEventListener('scroll', global.methods.debounce(scrollAlertCareer, 100));
    }

    function scrollAlertCareer() {
        var scrollBottom = getScrollTop() + window.innerHeight;

        if (scrollBottom > form.offsetTop && !global.methods.hasAttributeValue(alertElement, 'js-job-alert', 'is-hidden')) {
            global.methods.toggleAttributeValue(alertElement, 'js-job-alert', 'is-hidden');
        }
        else if (scrollBottom < form.offsetTop) {
            global.methods.removeAttributeValue(alertElement, 'js-job-alert', 'is-hidden');
        }
    };

    var formTrigger = document.querySelector('[js-job-full-form]'),
        jobForm = document.querySelector('[job-offer]');

    if (formTrigger) {
        formTrigger.addEventListener('click', toggleJobForm);
    }

    function toggleJobForm(e) {
        e.preventDefault();
        global.methods.toggleAttributeValue(jobForm, 'job-offer', 'form-full');
        document.querySelector('#cv-file').removeAttribute('required');

        $('html, body').animate({
            scrollTop: 0
        }, 0);

        $('[is-required]').attr('required', '');

        var jobReturnLinks = document.querySelectorAll('.job-return-link');

        if (jobReturnLinks.length > 0) {
            jobReturnLinks.forEach(function (element) {
                element.textContent = element.dataset.fullFormLabel;
                element.href = element.dataset.fullFormUrl;
            });
        }

        addExperience();
        addFormation();
    }

    $(document).on('click', '.js-add-experience, .js-remove-experience, .js-add-formation, .js-remove-formation', function(e) {
        e.preventDefault();
    });

    /* ------------------------------------------------------------------- */
    var experienceTrigger = document.querySelector('.js-add-experience'),
        experience = document.querySelector('.experience-template'),
        firstExperienceContainer = document.querySelector('#first-experience'),
        experienceContainer = document.querySelector('#other-experiences'),
        experienceIndex = 1;

    if (experienceTrigger) {
        experienceTrigger.addEventListener('click', addExperience);
    }

    function addExperience() {
        if (experienceIndex == 1) {
            firstExperienceContainer.appendChild(experience.cloneNode(true));
            var lastExperience = $('#first-experience .experience-template:last-child');
            lastExperience.find('.job-remove-link').addClass('hidden');
        } else {
            experienceContainer.appendChild(experience.cloneNode(true));
            var lastExperience = $('#other-experiences .experience-template:last-child');
        }

        lastExperience.removeClass('hidden');
        var baseTemplate = 'fields[workExperience][new' + experienceIndex + ']';
        var nameTemplate = baseTemplate + '[fields]';

        lastExperience.find('[for=experienceJobTitle]').attr('for', 'experienceJobTitle' + experienceIndex);
        lastExperience.find('#experienceJobTitle')
        .attr('id', 'experienceJobTitle' + experienceIndex)
        .attr('name', nameTemplate + '[jobTitle]').val('')
        .removeAttr('data-parsley-id').next().remove();

        lastExperience.find('[for=experienceCompany]').attr('for', 'experienceCompany' + experienceIndex);
        lastExperience.find('#experienceCompany')
        .attr('id', 'experienceCompany' + experienceIndex)
        .attr('name', nameTemplate + '[company]').val('')
        .removeAttr('data-parsley-id').next().remove();

        lastExperience.find('[for=experienceStartDate]').attr('for', 'experienceStartDate' + experienceIndex);
        lastExperience.find('#experienceStartDate')
        .attr('id', 'experienceStartDate' + experienceIndex)
        .attr('name', nameTemplate + '[startDate]').val('')
        .removeAttr('data-parsley-id').next().remove();

        lastExperience.find('[for=experienceEndDate]').attr('for', 'experienceEndDate' + experienceIndex);
        lastExperience.find('#experienceEndDate')
        .attr('id', 'experienceEndDate' + experienceIndex)
        .attr('name', nameTemplate + '[endDate]').val('');

        lastExperience.find('[for=gr-input-checkbox-swt]').attr('for', 'gr-input-checkbox-swt' + experienceIndex);
        lastExperience.find('#gr-input-checkbox-swt')
        .attr('id', 'gr-input-checkbox-swt' + experienceIndex)
        .attr('name', nameTemplate + '[stillWorkThere]').prop('checked', false);

        lastExperience.find('.parsley-error').removeClass('parsley-error');
        lastExperience.find('[type=hidden]').attr('name', baseTemplate + '[type]');

        experienceIndex++;
    }
    /* ------------------------------------------------------------------- */

    var formationTrigger = document.querySelector('.js-add-formation'),
        formation = document.querySelector('.formation-template'),
        firstFormationContainer = document.querySelector('#first-formation'),
        formationContainer = document.querySelector('#other-formations'),
        formationIndex = 1;

    if (formationTrigger) {
        formationTrigger.addEventListener('click', addFormation);
    }

    function addFormation() {
        if (formationIndex == 1) {
            firstFormationContainer.appendChild(formation.cloneNode(true));
            var lastFormation = $('#first-formation .formation-template:last-child');
            lastFormation.find('.job-remove-link').addClass('hidden');
        } else {
            formationContainer.appendChild(formation.cloneNode(true));
            var lastFormation = $('#other-formations .formation-template:last-child');
        }

        lastFormation.removeClass('hidden');
        var baseTemplate = 'fields[formations][new' + formationIndex + ']';
        var nameTemplate = baseTemplate + '[fields]';

        lastFormation.find('[for=formationName]').attr('for', 'formationName' + formationIndex);
        lastFormation.find('#formationName')
        .attr('id', 'formationName' + formationIndex)
        .attr('name', nameTemplate + '[formationName]')
        .val('').removeAttr('data-parsley-id').next().remove();

        lastFormation.find('[for=formationLocation]').attr('for', 'formationLocation' + formationIndex);
        lastFormation.find('#formationLocation')
        .attr('id', 'formationLocation' + formationIndex)
        .attr('name', nameTemplate + '[formationLocation]').val('')
        .removeAttr('data-parsley-id').next().remove();

        lastFormation.find('[for=formationGraduationYear]').attr('for', 'formationGraduationYear' + formationIndex);
        lastFormation.find('#formationGraduationYear')
        .attr('id', 'formationGraduationYear' + formationIndex)
        .attr('name', nameTemplate + '[graduationYear]').val('')
        .removeAttr('data-parsley-id').next().remove();

        lastFormation.find('[type=hidden]').attr('name', baseTemplate + '[type]');
        lastFormation.find('.parsley-error').removeClass('parsley-error');

        formationIndex++;
    }
    /* ------------------------------------------------------------------- */

    $('.job-form').on('click', '.js-remove-experience', function() {
        $(this).parents('.experience-template').remove();
    });

    $('.job-form').on('click', '.js-remove-formation', function() {
        $(this).parents('.formation-template').remove();
    });

    function getScrollTop(){
        if(typeof pageYOffset!= 'undefined'){
            //most browsers except IE before #9
            return pageYOffset;
        }
        else{
            var B = document.body; //IE 'quirks'
            var D = document.documentElement; //IE with doctype
            D = (D.clientHeight) ? D : B;
            return D.scrollTop;
        }
    }

}( window, jQuery, window.groots ));
