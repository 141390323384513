// ==========================================================================
// Artopex.js, powered by groots
// Author: Globalia
// Licensed under the WTFPL license
// ==========================================================================


// groots core !! DO NOT DELETE !!
// --------------------------------------------------------------------------

// Declaration of groots namespace
window.groots = window.groots || {};

// Display debug informations
window.groots.verbose = false;

// Require groots JS-Plugins
window.groots.require = {
    grInlineSVG:            'auto',
    grObjectfitEmulator:    'auto',
    grEqualizer:            'auto',
    grLazyLoader:           'auto',
    grNavigation:           'auto',
    grFormSelect:           'auto', //todo
    grFormUpload:           'auto',
    grFormNormalize:        false, //todo
    grFormValidation:       true, //todo
    grAccTabs:              'auto',
    grAccTabsMember:        'auto',
    grVideoYoutube:         'auto',
    grGoogleMap:            'auto'
};

// Let the magic happens
require('../../garden/_groots.js');

// --------------------------------------------------------------------------



window.styleArray = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    }
];

//if ($.fn.googlemaper === undefined) require('../../garden/buds/google-map/gr-googlemap.js');
require('magnific-popup');
require('slick-carousel');
require('jquery.event.move');
require('jquery-touchswipe');
require('./jquery.twentytwenty.js');

( function( window, $, global ) {
    "use strict";
    require('./_search.js');
    require('./_showall.js');
    require('./products.js');
    require('./_gallery.js');
    require('./_career.js');
    require('./_password-reveal.js');

    var YTPlayer = require('../../../node_modules/jquery-youtube-background/src/jquery.youtubebackground.js');

    if ($(window).width() > 1024) {
        $('.t-hero__cover-video').each(function() {
            var youtubeId = $(this).data('youtube-id');

            $(this).YTPlayer({
                fitToBackground: true,
                videoId: youtubeId,
                host: 'https://www.youtube-nocookie.com',
                playerVars : {
                    rel: 0,
                    controls: 0,
                    modestbranding: 1,
                    iv_load_policy: 3,
                    mute: 1
                }
            });
        })
    }

    $("[js-mfp-youtube]").magnificPopup({
        disableOn: function() {
            if (global.methods.maxWidth('sm')) {
                return false;
            }
            else {
                return true;
            }
        },
        type: "iframe"
    });

    $("[js-mfp-audio], [js-modal-landing]").magnificPopup({
        type: "inline"
    });

    $("[js-trigger-new-password]").click(function(e) {
        e.preventDefault();
        $(".gr-form__password").toggleClass("is-opened");
    });

    $("[js-nav-zm-trigger]").click(function(e) {
        e.preventDefault();
        $(".main, body").toggleClass("nav-is-opened");
        $(".navigation__trigger").toggleClass("is-active");
        $("[js-nav-panel]").toggleClass("is-opened");
    });

    $("[js-zm-change-page]").change(function() {
        var url = $(this).val();
        if (url) {
            document.location.href = url;
        }
    });

    $("[js-zm-notice-close]").on('click', function(e) {
        e.preventDefault();
        var cookieName = $(this).data('cookie-name');
        if (cookieName) {
            document.cookie = cookieName + "=1;path=/";

            $('.zm-notice').hide();
        }
    });

    $("[data-href]").click(function() {
        var url = $(this).attr("data-href");

        if (url && !$(this).hasClass("is-active")) {
            document.location.href = url;
        }
    });

    $("[mobile-nav-target]").on("click", function(e) {
        e.preventDefault();
        var navTarget = $(this).attr("mobile-nav-target");
        $('.mobile-nav__panel.is-opened').removeClass("is-opened");
        $("#" + navTarget).addClass("is-opened");
    });

    var dropdownMenuTimer;
    $(".nav__main li > .nav__link, .nav__top li > .nav__link--subpanel").mouseenter(function() {
        $(".nav__top li, .nav__main li").removeClass("is-active");
        clearTimeout(dropdownMenuTimer);
    });

    $('.zm-card-document').on('click', '[js-show-options]', function(e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parents('.zm-card-document').toggleClass("is-active");
    });

    $('.zm-card-document').on('click', function(e) {
        var target = $(e.target).parents(".zm-card-document__options-menu")[0];

        if (target === undefined) {
            $(this).removeClass("is-active");
        }
    });

    var menuOpened = false;
    var timer;

    $("[js-nav-trigger]").click(function(e) {
        e.preventDefault();
        $(".navigation__trigger").toggleClass("is-active");
        $("[js-nav-panel]").toggleClass("is-opened");
    });

    $('.nav__top li > .nav__link--subpanel').mouseenter(function() {
        $(this).parent().addClass("is-active");
        clearTimeout(dropdownMenuTimer);
    }).mouseleave(function() {
        var _this = this;
        dropdownMenuTimer = setTimeout(function() {
            $(_this).parent().removeClass("is-active");
        }, 1000);
    });

    $(".nav__link--subpanel").click(function(e) {
        e.preventDefault();
    });

    $("[gr-nav-trigger]").on('click', function() {
        $('body').toggleClass("nav-is-open");
    });

    $(".gmap-results__trigger").click(function() {
        $(".gmap-results, .gmap-container").toggleClass("is-opened");
        $('.nav').toggleClass("gmap-opened");
    });

    $("[js-showAvailableModularWalls]").change(function() {
        $("[js-showAvailableModularWalls]").prop("checked",  this.checked);
    });

    if (global.methods.isTouch()) {
        $("body").addClass("is-touch");
    }

    if ((!$("body").hasClass("is-touch") && !$(".gmap-overlay")[0] && $(".gmap")[0]) || !$(".gmap-overlay")[0] && $(".gmap")[0] && global.methods.minWidth('md')) {
        $(".gmap-results, .gmap-container").toggleClass("is-opened");
        $(".nav").toggleClass("gmap-opened");
    }

    $('a[href^="#"]').not('[href="#"]').not('.tab-link').click(function(event) {
        event.preventDefault();
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
            event.preventDefault();
            var offsetTop = 125;
            $('html, body').animate({
                scrollTop: target.offset().top - offsetTop
            }, 1000, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                };
            });
        }
    });

    $('[js-toggle-filters]').click(function(e) {
        e.preventDefault();
        $('.form--filters').addClass("is-fixed");
        $('body').addClass('search-is-open');
    });

    $('[js-toggle-filters-career]').click(function(e) {
        e.preventDefault();
        $('.form--filters-career').toggleClass("is-opened");
        $(this).toggleClass("is-opened");
    });

    $('[js-close-filters]').click(function(e) {
        e.preventDefault();
        $('.form--filters').removeClass("is-fixed");
        $('body').removeClass('search-is-open');
    });


    // $('.form--filters-fabrics .gr-form__field a').click(function(e) {
    //     // e.preventDefault();
    // });
    //

    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    var $body = $('body'),
    $filterOptions =  $('.filters-options');
    if ($filterOptions[0]) {

        var initialScrollTop = $(window).scrollTop();



        var buttonOffset = $filterOptions.offset().top;
        var buttonHeight = $filterOptions.outerHeight();

        $(window).on('scroll', debounce(function() {

            var currentScrollTop = $(window).scrollTop();
            var delta = currentScrollTop - initialScrollTop;

            if ($(window).scrollTop() > 94) {
                $filterOptions.addClass('is-sticky');
                $filterOptions.parent().css("padding-top", 100);
            }
            else {
                $filterOptions.removeClass('is-sticky');
                $filterOptions.parent().css("padding-top", 0);
            }

            initialScrollTop = currentScrollTop;
        }));
    }





    $(window).load(function() {
        $('[js-before-after]').twentytwenty();
    });

    $('[js-ressources-trigger]').click(function() {
        if ($(window).width() > 767) {
            $(this).parents('.ressources').toggleClass("is-opened");
        }
    });

    var $grnav = $(document).find('[gr-nav]');

    $grnav.find('.nav__top li.search-cta').hover(
        function() {
            $(this).find('.nav__subpanel input').focus();
        },
        function() {
            $(this).find('.nav__subpanel input').blur();
        }
    );

    $('.gallery-item').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        }
    });

    $('.gallery-item, .image-gallery-conf').click(function() {
        var magnificPopup = $.magnificPopup.instance;

        setTimeout(function() {
            $(".mfp-container").swipe( {
                swipeLeft:function(event, direction, distance, duration, fingerCount) {
                    magnificPopup.next();
                },

                swipeRight:function(event, direction, distance, duration, fingerCount) {
                    magnificPopup.prev();
                },
            });
        }, 500);
    });

    $('.iframe-sketchup').magnificPopup({
        type:'iframe'
    });


    $('.image-gallery').click(function(e) {
        e.preventDefault();
        $(this).parent().find(".gallery-item").eq(0).trigger("click");
    });

    // $('.magnificPopupCapable').magnificPopup({
    //     delegate: '.gallery-item-conf, .card__figure-icon--zoom',
    //     type: 'image',
    //     gallery:{
    //         enabled:true
    //     },
    //     image: {
    //         titleSrc: function(item) {
    //             var $el = $(item.el),
    //             downloadHref = $el.data('download'),
    //             downloadTitle = $el.data('download-title');

    //             return item.el.attr('title') + (downloadHref !== undefined && downloadTitle !== undefined ? ' (<a href="' + downloadHref + '" target="_blank">' + downloadTitle + '</a>)' : '');
    //         }
    //     }
    // });

    $('.magnificPopupCapable--gallery-conf').magnificPopup({
        delegate: '.gallery-item-conf',
        type: 'image',
        gallery: {
            enabled: true
        },
        image: {
            titleSrc: function (item) {
                var $el = $(item.el),
                    downloadHref = $el.data('download'),
                    downloadTitle = $el.data('download-title');

                return item.el.attr('title') + (downloadHref !== undefined && downloadTitle !== undefined ? ' (<a href="' + downloadHref + '" target="_blank">' + downloadTitle + '</a>)' : '');
            }
        }
    });

    // https://codepen.io/dimsemenov/pen/sHoxp?editors=1010
    // https://dimsemenov.com/plugins/magnific-popup/documentation.html#inline-type

    $('.magnificPopupCapable').magnificPopup({
        delegate: '.card__figure',
        type: 'image',
        gallery:{
            enabled:true
        },
        image: {
            titleSrc: function(item) {
                var $el = $(item.el),
                downloadHref = $el.data('download'),
                downloadTitle = $el.data('download-title');

                return item.el.attr('title') + (downloadHref !== undefined && downloadTitle !== undefined ? ' (<a href="' + downloadHref + '" target="_blank">' + downloadTitle + '</a>)' : '');
            }
        }
    });

    $('.magnificPopupCapable--listing').magnificPopup({
        delegate: '.card__figure-icon--zoom',
        type: 'image',
        gallery: {
            enabled: true
        },
        image: {
            titleSrc: function (item) {
                var $el = $(item.el),
                    downloadHref = $el.data('download'),
                    downloadTitle = $el.data('download-title');

                return item.el.attr('title') + (downloadHref !== undefined && downloadTitle !== undefined ? ' (<a href="' + downloadHref + '" target="_blank">' + downloadTitle + '</a>)' : '');
            }
        }
    });

    $('.zm-card-document--image').click(function(e) {
        if ($(e.target).hasClass('zm-card-document--image')) {
            $(this).find(".card__figure-icon--zoom").trigger('click');

        }
    });


    $('.image-gallery-conf').click(function(e) {
        e.preventDefault();
        $(this).parent().find(".gallery-item-conf").eq(0).trigger("click");
    });

    var autoplay;
    if ($('[js-slick]').hasClass("autoplay")) {
        autoplay = true;
    }
    else {
        autoplay = false;
    }

    $('[js-slick]').on('init', function(event, slick){
        if( $(this).hasClass("slider--home")) {
            $('.slick-dots').css('display', 'none');
        }
    });

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    var slides = window.slides !== undefined ? JSON.parse(window.slides) : [];
    var slideId = getUrlParameter('slide');

    $('[js-slick]').slick({
        initialSlide: (slideId.length && slides[slideId] ? slides[slideId] : 0),
        dots: true,
        autoplay: autoplay,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        customPaging: function (slick, index) {
            return '<div></div>';
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    customPaging: function (slick, index) {
                        return '<span>' + (index + 1) + '</span>' + ' / ' + slick.slideCount;
                    }
                }
            }
        ]

    }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if ($(slick.$slides.eq(nextSlide)).hasClass('header--home--video')) {
            if (window.groots.grVideoYoutube) {
                $.each( window.groots.grVideoYoutube.instances, function() {
                    if( $(this.selector)[0] == $(slick.$slides.eq(nextSlide)).find('[gr-video-youtube]')[0] ) this.player.playVideo();
                });
            }
        } else if ($(slick.$slides.eq(currentSlide)).hasClass('header--home--video')) {
            if (window.groots.grVideoYoutube) {
                $.each( window.groots.grVideoYoutube.instances, function() {
                    if( $(this.selector)[0] == $(slick.$slides.eq(currentSlide)).find('[gr-video-youtube]')[0] ) this.player.pauseVideo();
                });
            }
        }
    }).on('init', function(event, slick){
        //console.log($('[js-slick]').data('js-featured'));
        //console.log($.cookie('ArtopexLastSectionHandle'));
    });
    // .on('afterChange', function(event, slick, currentSlide, nextSlide){
    //     // finally let's do this after changing slides
    //     $('.slider-count #current').html(currentSlide+1);
    // });

    $('.slider-espace .slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        infinite: false
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
        // finally let's do this after changing slides
        $('.slider-count #current').html(currentSlide+1);
    });

    $('.slider-espace .slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-espace .slider-for',
        focusOnSelect: true,
        infinite: false
    });

    $('.slider-product .slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-product .slider-nav',
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    vertical: false,
                    verticalSwiping: false
                }
            }
        ]
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
        // finally let's do this after changing slides
        $('.slider-count #current').html(currentSlide+1);
    });
    $('.slider-product .slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-product .slider-for',
        focusOnSelect: true,
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    vertical: false,
                    verticalSwiping: false
                }
            }
        ]
    });

    $('.slider-case-study .slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
        // finally let's do this after changing slides
        $('.slider-count #current').html(currentSlide+1);
    });

    $('.slider-case-study .slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-case-study .slider-for',
        focusOnSelect: true,
        infinite: false
    });

    $('.slider-concept .slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        draggable: false
    });

    $('.slider-concept .btn--primary').click(function() {
        var index = $(this).attr('href').split("#")[1];
        $('.slider-concept .btn--primary').removeClass("is-active");
        $(this).addClass("is-active");
        $('.slider-concept .slider-for').slick('slickGoTo', index);
    });

    $('.slide__donkeytail').each(function() {
        var left = $(this).data('x');
        var top = $(this).data('y');

        if (left>50) {
            $(this).css('right',100-left + '%');
        } else {
            $(this).css('left',left + '%');
        }

        if (left>75) {
            $(this).addClass('dt-r');
        } else if (left<25) {
            $(this).addClass('dt-l');
        }

        if (top>50) {
            $(this).css('bottom',100-top + '%');
        } else {
            $(this).css('top',top + '%');
        }

        if (top>75) {
            $(this).addClass('dt-b');
        } else if (top<25) {
            $(this).addClass('dt-t');
        }

        $(this).on('click', '.card__cta', function() {
            var isActive = $(this).parent('.slide__donkeytail__container').hasClass("is-active");

            if (isActive) {
                $(this).parent('.slide__donkeytail__container').removeClass("is-active")
                return false;
            }

            $('.slide__donkeytail__container.is-active').removeClass('is-active');
            $(this).parent('.slide__donkeytail__container').toggleClass('is-active');
        });
    })

    if (window.groots.methods.isMobile('breakpoint') === false) {
        window.groots.grAccTabs && $.each( window.groots.grAccTabs.instances, function() {
            if ($(this.selector).hasClass('tabs--product')) {
                var heightCat = 0;
                $('.tabs--product__elements').each(function(){
                    calculateTabPosition($(this), 0);
                });

                this.onOpenTab = function () {
                    $('.tabs--product__elements').each(function(){
                        calculateTabPosition($(this), 0);
                    });
                };

                //Click the first.
                $(this.selector).find('.tab-link')[0].click();

                $(this.selector).find('.tab').each(function(){
                    if ($(this).hasClass('gr-active')) {
                        $(this).children('.tab-link')[0].click();
                    }
                });

            } else if ($(this.selector).hasClass('tabs--product__elements')) {
                this.onCloseTab = this.onOpenTab = function () {
                    calculateTabPosition($(this.selector), 0);
                }
            }
        });
    }

    // $('.tabs--product').children('.tab').first().click();

    $('.tabs--product .level-2.parent').on('click', function() {
        var currentPosition = $(this).parent().index();
        var nextPosition = 100;
        var nextFound = false;

        $(this).toggleClass('is-open');

        $(this).parents('.tabs--product__elements').find('.tab').each(function() {
            var position = $(this).index();
            var hasLevel2 = $(this).find('.level-2').length;
            var hasLevel3 = $(this).find('.level-3').length;

            if (hasLevel2 && (position > currentPosition) && !nextFound) {
                nextPosition = position;
                nextFound = true;
            }

            if (position > currentPosition && position < nextPosition) {
                if (hasLevel3) $(this).find('.level-3').toggleClass('parent-is-open');
            } else if (position !== currentPosition) {
                if (hasLevel2) $(this).find('.level-2').removeClass('is-open');
                if (hasLevel3) $(this).find('.level-3').removeClass('parent-is-open');
            }
        });
    });

    function calculateTabPosition(parent, offset) {
        var spacing = 0;
        var tabHeight = 0;

        parent.find('.tab').each(function() {
            var prevTab = $(this).prev('.tab');
            var prevTabLink = prevTab.find('.tab-link');
            var hasPrevTabLink = prevTabLink.length;
            var hasPrevTabLinkHidden = prevTabLink.is(':hidden');

            if (hasPrevTabLink && !hasPrevTabLinkHidden) {
                tabHeight = prevTabLink.outerHeight();
                offset += tabHeight;
                spacing = spacing + 10;
            }

            $(this).find('.tab-link').css({top: offset + spacing});
        });

        var sideHeight = offset + spacing + tabHeight;
        $('.tab-content[gr-acc-tabs-target*="active"] > ul').css({'min-height': sideHeight});
    }

    $('.form--filters').on('click', 'input[type=checkbox]', function(e) {
        if ($(this).closest('fieldset').find('input[type=checkbox]:checked').length)
        $(this).closest('fieldset').find('button').show();
        else
        $(this).closest('fieldset').find('button').hide();
        $(this).closest('.gr-form__field').find('input').prop('checked', $(this).prop('checked'));
        $(this).parentsUntil('.form--filters', '.gr-form__field').each(function() {
            var parent = $(this).parent('.gr-form__field').children('input'),
            siblings = $(this).parent('.gr-form__field').children('.gr-form__field').children('input');
            updateParentBasedOnSiblings(parent, siblings);
        });
    });

    function updateParentBasedOnSiblings(parent, siblings) {
        var numberOfInputs = siblings.length,
        numberOfInputsChecked = siblings.filter(function() {
            return $(this).prop('checked') || $(this).prop('indeterminate');
        }).length;
        parent.prop('indeterminate', numberOfInputsChecked > 0 && numberOfInputsChecked < numberOfInputs);
        parent.prop('checked', numberOfInputsChecked === numberOfInputs);
    }


    var $mediabox = $("<div gr-mediabox><figure></figure></div>");
    var $img = $("<img>");
    var $caption = $("<figcaption></figcaption>");

    $mediabox.find('figure').append($img).append($caption);

    $('body').append($mediabox);

    $('.card--planner').on('click', '.card__cta', function(event) {
        event.preventDefault();
        var $target = $(this).next('img');

        $img.attr('src', $target.attr("src"));
        $caption.html($target.attr("alt"));

        $mediabox.fadeIn('fast');

        $mediabox.click(function() {
            $mediabox.fadeOut('fast');
        });
    });

    $('.section--products').on('click', '.card__cta', function() {
        var $target = $(this).next().find('img');

        $img.attr('src', $target.attr("src"));
        $caption.html($target.attr("alt"));

        $mediabox.fadeIn('fast');

        $mediabox.click(function() {
            $mediabox.fadeOut('fast');
        });
    });

    $('.vt-cta, .video-cta').on('click', function() {
        var bannerCover = $(this).closest('.banner--sidebyside__picture').find('.banner--sidebyside__picture__wrapper');

        $(this).closest('.banner--sidebyside__picture').addClass('is-active');
        $(this).parent().fadeOut('fast');
        $(this).parent().prev('.cover').fadeOut('fast');

        if (bannerCover.hasClass('objectfit')) {
            bannerCover.find('iframe').addClass('is-active');
        }

        $(this).parent().parent().find('iframe')[0].src;
    })

    $('.video-cta-link').on('click', function(e) {
        var bannerCover = $(this).parent().next().find('.banner--sidebyside__picture__wrapper');

        if (bannerCover.hasClass('objectfit')) {
            bannerCover.find('iframe').addClass('is-active');
        }

        e.preventDefault();
        $(this).parent().next().find('.cover, .video-cta__container').fadeOut('fast');
        $(this).parent().next().find('iframe')[0].src;
    })

    $('.vt-new_cta').on('click', function() {
        var bannerCover = $(this).closest('.banner--inpage').find('.banner__cover');

        if (window.innerWidth > parseInt(window.groots.sassBreakpoints['md'], 10)) {
            if (bannerCover.hasClass('objectfit')) {
                bannerCover.find('iframe').addClass('is-active');
            }

            bannerCover.find('.cover').css('z-index', '-1');
            $(this).closest('.banner__inner').css({'z-index':'-1', 'opacity':0});
        }
        else {
            var url = bannerCover.find('iframe').prop("src");
            var win = window.open(url, '_blank');
            win.focus();
        }
    });

    $('.alert .icon-close').on('click', function() {
        $(this).parent().fadeOut();
    });

    $(document).on('scroll', function(){
        if(document.querySelector('.nav__blog') != null) {
            if (window.groots.methods.isVisible(document.querySelector('.nav__blog')) && !$('[gr-formbox]').hasClass('is-dismmissed')) {
                $('[gr-formbox]').addClass('is-dismmissed').fadeIn('slow');
            }
        }
    })

    $(document).on('click', '[gr-formbox]', function(event) {
        if(event.target == event.currentTarget)
        $('[gr-formbox]').fadeOut('fast');
    });

    $(document).on('click', '[data-js-popup]', function(event) {
        event.preventDefault();

        var winHeight = 400,
        winWidth = 600,
        winTop = ($(window).height() / 2) - (winHeight / 2),
        winLeft = ($(window).width() / 2) - (winWidth / 2),
        url = $(this).attr('href');

        window.open(url, '_blank', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    });

    /* AJAX MailChimp Form */
    $('.js-newsletter-form').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);
        var email = $form.find('input[name="email"]').val();
        var confirmationMessage;
        var $error = $form.find('.form-error');
        $error.text('');
        var $confirmation = $form.find('.newsletter-form-confirmation');

        if (! isValidEmail(email)) {
            $error.text($confirmation.data('member-invalid-email'));
            return false;
        }

        var $loading = $form.find('button[type="submit"]');

        $.ajax({
            url: $form.find('input[name="action"]').val(),
            dataType: 'JSON',
            type: 'POST',
            data: $form.serialize(),
            beforeSend: function() {
                $loading.addClass('icon-refresh processing');
            },
            success: function(data) {
                if (data) {
                    var code = data.errorCode;
                    switch (code) {
                        case 200:
                        confirmationMessage = $confirmation.data('success');
                        $form.find('.js-subscribe-form-item').hide();
                        $loading.toggleClass('is-ok');
                        break;
                        case 214: confirmationMessage = $confirmation.data('member-exists'); break;
                        case 1000: confirmationMessage = $confirmation.data('member-invalid-email'); break;
                        default: confirmationMessage = $confirmation.data('error');
                    }
                }
            },
            complete: function() {
                $loading.removeClass('icon-refresh processing');
                $confirmation.text(confirmationMessage);
            }
        });
    });
    /* End - AJAX MailChimp Form */

    function isValidEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    /* Forms */
    $('.js-form').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);
        var $loading = $form.find('button[type="submit"]');
        var $confirmation = $form.closest('div').find('.form-confirmation');

        $.ajax({
            url: $form.find('input[name="action"]').val(),
            dataType: 'JSON',
            type: 'POST',
            data: new FormData($form[0]),
            // Prevent jQuery from transforming the data into a query string
            // or worry about content type
            processData: false,
            contentType: false,
            cache: false,
            beforeSend: function() {
                $loading.addClass('icon-refresh processing');
            },
            success: function(data) {
                var $contactFormErrors = $form.closest('div').find('.form-errors');
                $contactFormErrors.html('');

                $confirmation.text($confirmation.data('success'));
                $form.hide();

                if( $('.js-form').hasClass("form--request") ) {
                    $('[gr-full-height]').attr("gr-full-height", "true");
                }
            },
            error: function ($xhr) {
                var data = $xhr.responseJSON;
                var $contactFormErrors = $form.closest('div').find('.form-errors');

                var errorMessage = '';
                if (data.errors) {
                    $.each(data.errors, function(key, value) {
                        errorMessage += '<p>' + value[0] + '</p>';
                    });
                }
                if (data.message) {
                    errorMessage += '<p>' + data.message + '</p>';
                }
                $contactFormErrors.html(errorMessage);

                if( $('.js-form').hasClass("form--request") ) {
                    $('[gr-full-height]').attr("gr-full-height", "true");
                }
            },
            complete: function() {
                $loading.removeClass('icon-refresh processing');
            }
        });
    });

    $('.js-form-alert').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);
        var $loading = $form.find('button[type="submit"]');
        var $messages = $form.find('.form-messages');
        var $title = $form.find('.h2');
        var $formError = $form.find('.form-error');
        var email = $form.find('input[name="email"]').val();

        if (! isValidEmail(email)) {
            $formError.text($messages.data('invalid-email'));
            return false;
        }

        $.ajax({
            url: $form.find('input[name="action"]').val(),
            dataType: 'JSON',
            type: 'POST',
            data: new FormData($form[0]),
            // Prevent jQuery from transforming the data into a query string
            // or worry about content type
            processData: false,
            contentType: false,
            cache: false,
            beforeSend: function() {
                $loading.addClass('icon-refresh processing');
            },
            success: function(data) {
                $formError.html('');

                if (data.success) {
                    $form.find('.js-subscribe-form-item').hide();
                    $messages.text(data.message);
                    $title.text(data.title);
                } else {
                    var errorMessage = '';
                    if (data.errors && data.errors.length > 0) {
                        $.each(data.errors, function(key, value) {
                            errorMessage += '<p>' + value + '</p>';
                        });
                    } else if (data.message && data.message.length > 0) {
                        errorMessage = data.message;
                    }

                    $formError.html(errorMessage);
                }

                if( $('.js-form').hasClass("form--request") ) {
                    $('[gr-full-height]').attr("gr-full-height", "true");
                }
            },
            complete: function() {
                $loading.removeClass('icon-refresh processing');
            }
        });
    });
    /* End - Forms */

    $('body').on('keydown', '[data-input=numeric]', function(e){
        // Allow: backspace, delete, tab, escape and enter
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
        // Allow: Ctrl+A, Command+A
        (e.keyCode == 65 && ( e.ctrlKey === true || e.metaKey === true ) ) ||
        // Allow: home, end, left, right, down, up
        (e.keyCode >= 35 && e.keyCode <= 40)) {
            // Let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });

    $('[gr-expander-trigger]').on('click', function(e) {
        e.preventDefault();
        var target = $(this).parent().find('[gr-expander-target]');

        if ( target.attr('gr-expander-target') != 'active' ) {
            target.attr('gr-expander-target', 'active');
            $(this).attr('gr-expander-trigger', 'active');
        } else {
            target.attr('gr-expander-target', '');
            $(this).attr('gr-expander-trigger', '');
        }
    });

    $('.section--product__detail [gr-acc-tabs-trigger]').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);

        if($('[gr-nav-trigger]').is(':visible')) {
            setTimeout(function() {
                var $header = $('.nav.nav--product'),
                    headerHeight = $header.height(),
                    nextTopPosition;

                nextTopPosition = $this.offset().top - headerHeight;
                $("html, body").animate({ scrollTop: nextTopPosition+"px" });
            }, 100);
        }

    });


    // Find a job (select)
    // --------------------------------------------
    $('.js-city').each(function () {
        var $selectItems = $(this).parent().find('.gr-select__options li');

        if ($selectItems.length > 0) {
            $selectItems.on('click', function(e) {
                var pageUrl = window.location.href.split(/[?#]/)[0];
                var $this = $(this);
                var id = $this.attr('rel');

                window.location.href = pageUrl + '?city=' + id + '#jobs-list';
            });
        }
    });

    // PhotoSwipeLightbox
    // --------------------------------------------
    if( $('.swiper-photoSwipe').length > 0 ) {
        var swiper = new Swiper('.swiper-photoSwipe', {
            loop: false,
            slidesPerView: "auto",
            centeredSlides: true,
            spaceBetween: 20,
            autoplay: {
                delay: 2500,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-custom",
                prevEl: ".swiper-button-prev-custom",
            },
            breakpoints: {
                400: {
                    centeredSlides: false,
                }
            }
        });
    }

    if( $('.swiper-photoSwipe-single').length > 0 ) {
        var swiper = new Swiper('.swiper-photoSwipe-single', {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: ".swiper-button-next-custom",
                prevEl: ".swiper-button-prev-custom",
            }
        });
    }

    if( $('.lightbox-photoSwipe').length > 0 ) {
        var swiperlightbox = new PhotoSwipeLightbox({
            gallery: '.lightbox-photoSwipe',
            children: 'a',
            bgOpacity: 0.9,
            padding: { top: 20, bottom: 40, left: 100, right: 100 },
            // dynamic import is not supported in UMD version
            pswpModule: PhotoSwipe,
            preload: [1, 4],
            showHideOpacity: true,
            loop: false,
            showHideAnimationType: 'fade',
        });
        swiperlightbox.init();
    }

    if (window.location.hash && $(window.location.hash).length > 0) {
        $('html, body').animate({ scrollTop: $(window.location.hash).offset().top - $('#header-nav').height() }, 600);
    }

    $('#order-considalation-form').on('submit', function(e) {
        e.preventDefault();

        $('#btnsumbit').attr('disabled', 'disabled');

        var form = $(this)[0];
        var contact = form.querySelector('input[name="contact"]');
        var error = form.querySelector('#contact-error');
        contact.classList.remove('fui-error');
        error.classList.add('fui-hidden');
        if (contact.value === '') {
            
            contact.focus();
            contact.classList.add('fui-error');
            error.classList.remove('fui-hidden');


            $('#btnsumbit').attr('disabled', false);
            return;
        }

        var data = new FormData(form);
        data.append(window.csrfTokenName, window.csrfTokenValue);

        $.ajax({
            url: form.getAttribute('action'),
            dataType: 'JSON',
            type: 'POST',
            data: data,
            processData: false,
            contentType: false,
            success: function(data) {
                console.log(data);

                if(data.success) {
                    $('.fui-alert-success').removeClass('fui-hidden');
                    $('.form-field-group').addClass('fui-hidden');
                    $('#btnsumbit').css('display', 'none');
                }

                if(data.error) {
                    $('#btnsumbit').attr('disabled', false);
                    $('.fui-alert-error').removeClass('fui-hidden');
                    $('html, body').animate({
                        scrollTop: 0
                    }, 600);
                }

            },
            error: function(data) {
                console.log(data);
                $('#btnsumbit').attr('disabled', false);
                $('.fui-alert-error').removeClass('fui-hidden');
                // scroll to the top of the form
                $('html, body').animate({
                    scrollTop: $('#order-considalation-form').offset().top
                }, 600);
            }
        });
    });

}( window, jQuery, window.groots ));

