/*!
* jQuery InlinerSVG plugin
* Author: Globalia
* Licensed under the WTFPL license
*
* Replace all SVG images with the class .svg in inline SVG
*/

( function( global ) { // eslint-disable-line

    var pluginName = 'grInlineSVG';

    // Constructor
    function grInlineSVG(selector) {
        // Create global references
        this.name = pluginName;
        this.selector = typeof selector === 'string' ? document.querySelector(selector) : selector;

        this.init();
    };

    grInlineSVG.prototype.init = function init() {
        if (this.selector === null) {
            throw new Error(pluginName + ': fail - something wrong with your selector');
        } else {
            var image = this.selector;
            var url = image.src || image.getAttribute('data-src');
            var attributes = image.attributes;
            var httpRequest = new XMLHttpRequest();
            image.setAttribute('gr-svg-inliner', 'ready');
            if (groots.verbose) console.log('grInlineSVG(' + url + '): ready');

            httpRequest.onreadystatechange = function() {
                global.methods.toggleAttributeValue(image, 'gr-svg-inliner', 'processing');
                // readyState 4 = complete
                if (httpRequest.readyState === 4) {

                    if (httpRequest.status === 404 || httpRequest.responseXML === null) {
                        console.log('Unable to load SVG file: ' + url);
                        return false;
                    }

                    if (httpRequest.status === 200) {
                        var xmlDoc;
                        try {
                            var parser = new DOMParser();
                            xmlDoc = parser.parseFromString(httpRequest.responseText, 'text/xml');
                        }
                        catch (e) {
                            xmlDoc = undefined;
                        }
                        if (!xmlDoc || xmlDoc.getElementsByTagName('parsererror').length) {
                            console.log('Unable to parse SVG file: ' + url);
                            return false;
                        }
                        else {
                            var svg = xmlDoc.documentElement;

                            // Remove some of the attributes that aren't needed
                            svg.removeAttribute('xmlns:a');
                            svg.removeAttribute('width');
                            svg.removeAttribute('height');
                            svg.removeAttribute('x');
                            svg.removeAttribute('y');
                            svg.removeAttribute('enable-background');
                            svg.removeAttribute('xmlns:xlink');
                            svg.removeAttribute('xml:space');
                            svg.removeAttribute('version');

                            // Add the attributes from the original <img> except src, alt or class
                            Array.prototype.slice.call(attributes).forEach(function(attribute) {
                                if(attribute.name !== 'src' && attribute.name !== 'alt') {
                                    svg.setAttribute(attribute.name, attribute.value);
                                }
                            });

                            // Add accessibility attributes
                            if(attributes.alt) {
                                svg.setAttribute('aria-labelledby', 'title');

                                var title = document.createElementNS('http://www.w3.org/2000/svg', 'title'),
                                    titleText = document.createTextNode(attributes.alt.value);

                                title.appendChild(titleText);
                                svg.insertBefore(title, svg.firstChild);
                            }
                            svg.setAttribute('role', 'img');
                            svg.setAttribute('gr-svg-inliner-src', url);

                            // Replace the image with the svg
                            image.parentNode.replaceChild(svg, image);

                            global.methods.removeAttributeValue(svg, 'gr-svg-inliner', 'processing');
                            global.methods.toggleAttributeValue(svg, 'gr-svg-inliner', 'done');
                            if (groots.verbose) console.log('grInlineSVG(' + url + '): done');
                        }
                    }
                }
            };

            httpRequest.onerror = function() {
                console.log('grInlineSVG: failed - connection error');
            };

            httpRequest.open('GET', url, true);

            // Treat and parse the response as XML, even if the server sends us a different mimetype
            if (httpRequest.overrideMimeType) httpRequest.overrideMimeType('text/xml');

            httpRequest.send();
        }
    };

    // Destroy - remove listeners to prevent from memory leak
    grInlineSVG.prototype.destroy = function destroy() {
        global.methods.removeAttributeValue(this.selector, 'gr-svg-inliner', 'ready processing done');
        if (groots.verbose) console.log(pluginName + ': destroyed');
    };

    // jQuery wrapper, if available
    if(window.jQuery) {
        $.fn[pluginName] = function (options) {
            options = options || config;
            return this.each(function() {
                // add plugin to element data
                if (!$.data(this, pluginName) ) $.data(this, pluginName, new grInlineSVG(this));
            });
        };
    }

    // Export to CommonJS
    if (typeof module !== 'undefined' && module.exports) module.exports = grInlineSVG;
    // Export to Browser
    else global[pluginName] = grInlineSVG; // eslint-disable-line

}( window.groots ));
