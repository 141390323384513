// ==========================================================================
// groots core
// Author: Globalia
// Licensed under the WTFPL license
// ==========================================================================

window.jquery = window.jQuery = window.$ = require('jquery'); // Will be removed

var sassToJs = require('sass-to-js');

window.groots.methods = {

    objectAssign : Object.assign || function (srcObj) { // eslint-disable-line
        for (var i = 1; i < arguments.length; i++) {
            for (var objProperty in arguments[i]) {
                if (Object.prototype.hasOwnProperty.call(arguments[i], objProperty)) srcObj[objProperty] = arguments[i][objProperty];
            }
        }
        return srcObj;
    },

    debounce : function (func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    isVisible : function (el) {
        var windowWidth = window.innerWidth,
            windowHeight = window.innerHeight;
        if (windowWidth === null || windowHeight === null) return false;
        var rect = el.getBoundingClientRect();
        var elTop = rect.top;
        var elLeft = rect.left;
        var elWidth = rect.width;
        var elHeight = rect.height;
        return elTop < windowHeight && elTop + elHeight > 0 && elLeft < windowWidth && elLeft + elWidth > 0;
    },

    // Add or toggle an attribute value
    toggleAttributeValue : function (el, attribute, value, toggleClass) {
        if (toggleClass === undefined) var toggleClass = false;
        if (!el || !el.hasAttribute(attribute)) return;

        var values = el.getAttribute(attribute).split(' ');
        var existingIndex = values.indexOf(value);

        if (existingIndex >= 0) values.splice(existingIndex, 1);
        else values.push(value);
        if (toggleClass) el.classList.toggle('gr-' + value);

        el.setAttribute(attribute, values.join(' '));
    },

    // Remove attribute value(s)
    removeAttributeValue : function (el, attribute, value, removeClass) {
        if (removeClass === undefined) var removeClass = false;
        if (!el || !el.hasAttribute(attribute)) return;
        var values = el.getAttribute(attribute).split(' ');

        var valuesToRemove = value.split(' ');

        for (var i = valuesToRemove.length - 1; i >= 0; i--) {
            var existingIndex = values.indexOf(valuesToRemove[i]);
            if (existingIndex >= 0) values.splice(existingIndex, 1);
            if (removeClass) el.classList.remove('gr-' + valuesToRemove[i]);
        }

        el.setAttribute(attribute, values.join(' '));
    },

     // Check if element has attribute value
     hasAttributeValue : function (el, attribute, value) {
        if (!el || !el.hasAttribute(attribute)) return;

        var values = el.getAttribute(attribute).split(' ');
        var existingIndex = values.indexOf(value);

        if (existingIndex >= 0) {
            return true;
        } else {
            return false;
        };
    },

    // Wrap wrapper around nodes
    wrapAll : function (nodes, wrapper) {
        // Cache the current parent and previous sibling of the first node.
        var parent = nodes[0].parentNode;
        var previousSibling = nodes[0].previousSibling;

        // Place each node in wrapper.
        //  - If nodes is an array, we must increment the index we grab from
        //    after each loop.
        //  - If nodes is a NodeList, each node is automatically removed from
        //    the NodeList when it is removed from its parent with appendChild.
        for (var i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && i++) {
            wrapper.appendChild(nodes[i]);
        }

        // Place the wrapper just after the cached previousSibling,
        // or if that is null, just before the first child.
        var nextSibling = previousSibling ? previousSibling.nextSibling : parent.firstChild;
        parent.insertBefore(wrapper, nextSibling);

        return wrapper;
    },

    isMobile : function (condition, breakpoint) {
        var isMobile = false,
            isTouch = false;

        function testBreakpoint() {
            if (typeof(breakpoint) === 'undefined') breakpoint = 'md';

            return window.innerWidth < parseInt(window.groots.sassBreakpoints[breakpoint], 10);
        }

        function testTouch() {
            return 'ontouchstart' in window ||
                window.DocumentTouch && document instanceof window.DocumentTouch ||
                navigator.maxTouchPoints > 0 ||
                window.navigator.msMaxTouchPoints > 0;
        }

        switch(condition) {
            case 'breakpoint':
                return testBreakpoint();
            case 'touch':
                return testTouch();
            default:
                return testTouch() && testBreakpoint();
        }
    },

    minWidth : function (breakpoint) {
        if (typeof(breakpoint) === 'undefined') breakpoint = 'md';

        return window.innerWidth >= parseInt(window.groots.sassBreakpoints[breakpoint], 10);
    },

    // Check if viewport is smaller than breakpoint (based on breakpoints defined in SASS)
    maxWidth : function (breakpoint) {
        if (typeof(breakpoint) === 'undefined') breakpoint = 'md';

        return window.innerWidth < parseInt(window.groots.sassBreakpoints[breakpoint], 10);
    },

    // Check if touch device
    isTouch : function () {
        return 'ontouchstart' in window ||
            window.DocumentTouch && document instanceof window.DocumentTouch ||
            navigator.maxTouchPoints > 0 ||
            window.navigator.msMaxTouchPoints > 0;
    }

}

// Require groots JS-Plugins
if (window.groots.grInlineSVG === undefined && window.groots.require.grInlineSVG) var grInlineSVG = require('./buds/grInlineSVG/grInlineSVG.js')
if (window.groots.grObjectfitEmulator === undefined && window.groots.require.grObjectfitEmulator) var grObjectfitEmulator = require('./buds/grObjectfitEmulator/grObjectfitEmulator.js');
if (window.groots.grEqualizer === undefined && window.groots.require.grEqualizer) var grEqualizer = require('./buds/grEqualizer/grEqualizer.js');
if (window.groots.grLazyLoader === undefined && window.groots.require.grLazyLoader) var grLazyLoader = require('./buds/grLazyLoader/grLazyLoader.js');
if (window.groots.grNavigation === undefined && window.groots.require.grNavigation) var grNavigation = require('./buds/grNavigation/grNavigation.js');
if (window.groots.grAccTabs === undefined && window.groots.require.grAccTabs) var grAccTabs = require('./buds/grAccTabs/grAccTabs.js');
if (window.groots.grAccTabsMember === undefined && window.groots.require.grAccTabsMember) var grAccTabsMember = require('./buds/grAccTabsMember/grAccTabsMember.js');
if (window.groots.grFormSelect === undefined && window.groots.require.grFormSelect) var grFormSelect = require('./buds/grFormSelect/grFormSelect.js');
if (window.groots.grFormUpload === undefined && window.groots.require.grFormUpload) var grFormUpload = require('./buds/grFormUpload/grFormUpload.js');
if (window.groots.grFormNormalize === undefined && window.groots.require.grFormNormalize) var grFormNormalize = require('./buds/grFormNormalize/grFormNormalize.js');
if (window.groots.grFormValidation === undefined && window.groots.require.grFormValidation) var grFormValidation = require('./buds/grFormValidation/grFormValidation.js');
if (window.groots.grGoogleMap === undefined && window.groots.require.grGoogleMap) var grGoogleMap = require('./buds/grGoogleMap/grGoogleMap.js');
if (window.groots.grVideoYoutube === undefined && window.groots.require.grVideoYoutube) var grVideoYoutube = require('./buds/grVideoYoutube/grVideoYoutube.js');

// Instantiation of groots JS-Plugins
( function( global ) {

    "use strict"; // Start of use strict

    // Set Breakpoints Map
    global.sassBreakpoints = sassToJs( document.querySelector('body'), { pseudoEl: ':before', cssProperty: 'content', debug: true } );

    // gr-Instantiation
    var all = {}, index;

    //if (!/MSIE|Trident|Edge/.test(navigator.userAgent)) {
        if (document.querySelectorAll('img.svg').length && window.groots.require.grInlineSVG == 'auto') {
            window.groots.grInlineSVG = { instances: {} };
            all = document.querySelectorAll('img.svg');
            index = -1;
            while (all[++index])
                global.grInlineSVG.instances[index] = new grInlineSVG(all[index]);
        }
    // }

    if (document.querySelectorAll('[gr-equalizer]').length && window.groots.require.grEqualizer == 'auto') {
        window.groots.grEqualizer = { instances: {} };
        all = document.querySelectorAll('[gr-equalizer]');
        index = -1;
        while (all[++index])
            global.grEqualizer.instances[index] = new grEqualizer(all[index]);
    }

    if (!/MSIE|Trident|Edge/.test(navigator.userAgent)) {
        if (document.querySelectorAll('[gr-lazyloader]').length && window.groots.require.grLazyLoader == 'auto' && typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1) {
            window.groots.grLazyLoader = { instances: {} };
            all = document.querySelectorAll('[gr-lazyloader]');
            index = -1;
            while (all[++index])
                global.grLazyLoader.instances[index] = new grLazyLoader(all[index]);
        }
    }

    // if (/MSIE|Trident|Edge/.test(navigator.userAgent) && document.querySelectorAll('img, video').length) {
    //     window.groots.grObjectfitEmulator = { instances: {} };
    //     all = document.querySelectorAll('img');
    //     index = -1;
    //     while (all[++index]) {
    //         var node = all[index];
    //         if(node.currentStyle)
    //             var objectFit = node.currentStyle['object-fit'];
    //         else if(window.getComputedStyle)
    //             var objectFit = document.defaultView.getComputedStyle(node, null).getPropertyValue('font-family');
    //
    //         if (objectFit && /^(contain|cover|"cover")$/.test(objectFit))
    //             global.grObjectfitEmulator.instances[index] = new grObjectfitEmulator(all[index]);
    //     }
    // }

    if (document.querySelectorAll('[gr-form] input[type=file]').length && window.groots.require.grFormUpload == 'auto') {
        window.groots.grFormUpload = { instances: {} };
        all = document.querySelectorAll('[gr-form] input[type=file]');
        index = -1;
        while (all[++index])
            global.grFormUpload.instances[index] = new grFormUpload(all[index]);
    }

    if (document.querySelectorAll('[gr-form] select').length && window.groots.require.grFormSelect == 'auto') {
        window.groots.grFormSelect = { instances: {} };
        all = document.querySelectorAll('[gr-form] select:not([multiple])');
        index = -1;
        while (all[++index])
            global.grFormSelect.instances[index] = new grFormSelect(all[index]);
    }

    if (document.querySelectorAll('[gr-nav]').length && window.groots.require.grNavigation == 'auto') {
        window.groots.grNavigation = { instances: {} };
        all = document.querySelectorAll('[gr-nav]');
        index = -1;
        while (all[++index])
            global.grNavigation.instances[index] = new grNavigation(all[index]);
    }

    if (document.querySelectorAll('[gr-acc-tabs]').length && window.groots.require.grAccTabs == 'auto') {
        window.groots.grAccTabs = { instances: {} };
        all = document.querySelectorAll('[gr-acc-tabs]');
        index = -1;
        while (all[++index])
            global.grAccTabs.instances[index] = new grAccTabs(all[index]);
    }

    if (document.querySelectorAll('[gr-acc-tabs-member]').length && window.groots.require.grAccTabsMember == 'auto') {
        window.groots.grAccTabsMember = { instances: {} };
        all = document.querySelectorAll('[gr-acc-tabs-member]');
        index = -1;
        while (all[++index])
            global.grAccTabsMember.instances[index] = new grAccTabsMember(all[index]);
    }

    if (document.querySelectorAll('[gr-google-map]').length && window.groots.require.grGoogleMap == 'auto') {
        window.groots.grGoogleMap = { instances: {} };
        all = document.querySelectorAll('[gr-google-map]');
        index = -1;
        while (all[++index])
            global.grGoogleMap.instances[index] = new grGoogleMap(all[index]);
    }

    if (document.querySelectorAll('[gr-video-youtube]').length && window.groots.require.grVideoYoutube == 'auto') {
        window.groots.grVideoYoutube = { instances: {} };
        all = document.querySelectorAll('[gr-video-youtube]');
        index = -1;
        while (all[++index])
            global.grVideoYoutube.instances[index] = new grVideoYoutube(all[index]);
    }

    // window.scrollbarWidth = function() {
    //     var $inner = $('<div style="width: 100%; height:1px;"></div>'),
    //         $outer = $('<div style="width:1px;height:1px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),
    //         inner = $inner[0],
    //         outer = $outer[0];
    //     $('body').append(outer);
    //     var width1 = inner.offsetWidth;
    //     $outer.css('overflow', 'scroll');
    //     var width2 = outer.clientWidth;
    //     $outer.remove();
    //     return (width1 - width2);
    // }

} )( window.groots );
